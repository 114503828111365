<template>
	<div class="subModal scroll">
		<div class="item" v-for="(a, index) in arts" :key="index">
			<div class="title">{{a.title}}</div>
			<div class="del" @click="setDel(a.id)"><i class="fa-solid fa-trash"></i></div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'MyArt',
	data(){
		return {
			arts: []
		}
	},
	methods:{
		async getArt(){
			const req = await axios.get(base+'getItens/getMyArts')
			this.arts = req.data
		},
		async setDel(id){
			const alerta = confirm("Tem certeza?");
			if (alerta) {
				const req = await axios.post(base+'getItens/delMyArt', {
					id: id
				})
				this.$emit('textAlerta', req.data.text)
				this.getArt()
			}
		}
	},
	mounted(){
		this.getArt()
	}
}
</script>

<style scoped>
.scroll {
	overflow: auto;
	position: relative;
	height: 260px;
}

.subModal .item {
	position: relative;
	float: left;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	font-size: 14px;
	line-height: 30px;
	background-color: #EFF2F5;
	border-radius: 12px;
	margin-top: 6px;
}

.subModal .item .del {
	position: absolute;
	right: 10px;
	top: 0px;
	width: 10px;
	height: 10px;
	color: ;
	font-size: 14px;
	font-weight: 600;
	z-index: 99;
}

</style>