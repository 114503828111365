<template>
	<div class="modal pedidos">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Fazer pedido</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit="setPed">
					<input type="text" placeholder="Escreva aqui..." v-model="pedido" minlength="5">
					<button>Enviar</button>
				</form>
			</div>
		</div>
		<Transition name="slide-fade">
			<Alerta v-if="this.textA != ''" :text="this.textA" />
		</Transition>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModPedido',
	components: {
		Alerta
	},
	data(){
		return {
			pedido: '',
			textA: ''
		}
	},
	methods: {
		async setPed(e){
			e.preventDefault();
			if(this.pedido != ''){
				const req = await axios.post(base+'page/requests', {
					text: this.pedido
				})
				this.textA = req.data.text

				setInterval(() => {
					this.textA = ''
				}, 3000);

				if(req.data.error){
					this.$emit('close', 'ped')
					// this.$router.go()
				}
			}else{
				this.textA = 'Digite seu pedido!'
			}
		},
	}
}
</script>

<style scoped>
.pedidos .top {height: 50px;}
</style>