<template>
	<div class="page sear">
		<div class="top">
			<div class="actions">
				<div class="back" @click="$emit('clear')">Voltar</div>
			</div>
		</div>
		<div class="content">
			<div class="box" v-for="(n, index) in res" :key="index">
				<router-link :to="{path: `${n.router}/${n.id}/${n.url}`, name: n.name, params: {id: n.id, url: n.url}}">
                    <div class="img" :style="{backgroundImage: 'url('+this.uploads+'noticias/'+n.imagem+')'}"></div>
                    <div class="title nobr">{{n.titulo}}</div>
                </router-link>
			</div>
		</div>
		<!-- <div class="content" v-else>
			<p>Nada encontrado!</p>
		</div> -->
	</div>
</template>

<script>
import { base } from '../../var.js'
import axios from 'axios'

export default {
	name: 'SearchItem',
	props: ['search'],
	data(){
		return {
			res: [],
		}
	},
	methods: {
		async getSearch() {
			const req = await axios.post(base+'page/search', {
				text: this.search
			})
			this.res = req.data;
			console.log(this.res)
		}
	},
	mounted(){
		this.getSearch()
		console.log(this.search)
	}
}
</script>

<style scoped>
.sear .top .actions .back {
	float: left;
	width: auto;
	height: 40px;
	color: #fff;
	background-color: var(--color1);
	border-radius: 20px;
	padding: 0 16px;
	font-weight: 600;
	font-size: 14px;
	line-height: 40px;
	user-select: none;
}
</style>