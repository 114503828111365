<template>
	<div class="item last">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title"><span>Novos</span> emblemas</div>
			</div>
			<div class="actions">
				<router-link :to="{path: '/api', name: 'HabbletApi'}">
					<button class="plus">Ver mais</button>
					</router-link>
			</div>
		</div>
		<div class="content">
			<div class="box" v-for="(b, index) in badges" :key="index" :style="{backgroundImage: 'url(https://images.habblet.city/c_images/album1584/'+b.code+'.gif)'}">
				<item-tip :title="b.name" top="-50%"></item-tip>
			</div>
		</div>
	</div>
</template>

<script>
import ItemTip from '../usefulness/tooltip.vue'
import axios from 'axios'

export default {
	name: 'BadgesNewsItem',
	components: {
		ItemTip
	},
	data(){
		return {
			badges: [],
			limit: 9
		}
	},
	methods: {
		async getBad(l){
			const req = await axios.get('https://api.pauloferraco.com.br/v3/?dir=habblet_badges&limit='+l+'&pag=1')
			this.badges = req.data.badges
		}
	},
	mounted(){
		if(navigator.userAgentData.mobile){
			this.limit = 12
		}
		this.getBad(this.limit);
	}
}
</script>

<style scoped>

.last {
	position: relative;
	width: 100%;
	padding: 10px 0;
}

.last .top .icon {
	background-color: #CA70A3;
	background-position: -36px -96px;
}

.last .top .plus {
	width: auto;
	padding: 0 10px;
	background-color: var(--color1);
	color: #fff;
	font-weight: 600;
}

.last .content {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.last .content .box {
	position: relative;
	float: left;
	width: 90px;
	height: 80px;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 12px;
	background-color: #C3CED9;
	transition: all .4s ease;
	margin: 0 8px 10px 2px;
}
.last .content .box:hover {background-color: #CA70A3;}
</style>