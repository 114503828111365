<template>
	<div class="subModal">
		<form @submit.prevent="setPass">
			<input type="password" name="pass" placeholder="Senha" v-model="pass">
			<input type="password" name="passr" placeholder="Repita a senha" v-model="passr">
			<button>Confirmar</button>
		</form>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'AltPass',
	data(){
		return {
			pass: '',
			passr: ''
		}
	},
	methods:{
		async setPass(){
			const req = await axios.post(base+'page/changePass', {
				pass: this.pass,
				passr: this.passr
			})
			this.$emit('textAlerta', req.data.text)
		}
	}
}
</script>

<style scoped>

</style>