<template>
	<div class="subModal scroll">
		<div class="item" v-for="t in tics" :key="t.id">
			<div class="title" @click="$emit('TickChat', t.id)">{{t.assunto}}</div>
			<div class="status" :class="t.status"></div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'MineTic',
	data(){
		return {
			tics: []
		}
	},
	methods:{
		async getTic(){
			const req = await axios.post(base+'page/tickets', {
				item: 'get',
			})
			this.tics = req.data
		},
		async setDel(id){
			const alerta = confirm("Tem certeza?");
			if (alerta) {
				const req = await axios.post(base+'page/tickets', {
					del: 'delTic',
					id: id
				})
				this.$emit('textAlerta', req.data.text)
			}
		}
	},
	mounted(){
		this.getTic();
	}
}
</script>

<style scoped>
.scroll {
	overflow: auto;
	position: relative;
	height: 250px;
}

.subModal .item {
	position: relative;
	float: left;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	font-size: 14px;
	line-height: 30px;
	background-color: #EFF2F5;
	border-radius: 12px;
	margin-top: 6px;
}

.subModal .item .status {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: #34A638;
}
.subModal .item .status.false{background-color: #D21111;}

</style>