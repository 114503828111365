<template>
	<div class="modal shopItem">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="toldo"></div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<div class="image" :style="{backgroundImage: 'url('+item_shop.image+')'}"></div>
				<div class="data">
					<span class="title">{{ item_shop.name }}</span>
					<div class="info"><strong>Estoque:</strong>&nbsp;{{ item.quantity }}</div>
					<div class="info yellow"><i class="fa-solid fa-coins"></i>&nbsp;&nbsp;{{ item.price }}</div>
					<div class="info">
						<div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+item_shop.user+'&action=std,crr=&gesture=std&direction=2&head_direction=2&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
						<span>Postado por <strong>{{ item_shop.user }}</strong></span>
					</div>
				</div>
			</div>
			<form @submit="Buy">
				<button v-if="item_shop.logged && item_shop.quantity > 0 && item_shop.vendido == false">Comprar</button>
				<button v-if="item_shop.vendido == true" disabled>Comprado</button>
			</form>
		</div>
	</div>
	<Transition name="slide-fade">
		<Alerta v-if="this.textA != ''" :text="this.textA" />
	</Transition>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ItemBuy',
	components: {
		Alerta
	},
	props: {
		item: {
			type: Object,
			default: () => {}
		},
	},
	data(){
		return {
			item_shop: [],
			textA: ''
		}
	},
	methods: {
		async getItem(){
			const req = await axios.post(base+'content/store/get', {
				id: this.item.id
			})
			this.item_shop = req.data.store[0]
		},
		async Buy(e){
			e.preventDefault();
			const req = await axios.post(base+'content/store/buy', {
				id: this.item.id
			});
			this.textA = req.data.text
			this.getItem();
		}
	},
	mounted(){
		this.getItem()
	}
}
</script>

<style scoped>

.box {
	width: 600px;
}

.box .top {
	position: relative;
	border: 0;
}

.box .top .close {color: #fff;}

.box .top .toldo {
	position: absolute;
	left: -20px;
	top: -10px;
	width: 639px;
	height: 62px;
	background-image: url(../../assets/tenda2.png);
	background-position: center;
	background-repeat: no-repeat;

}

.box .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-bottom: 40px;
}

.box .content .image {
	width: 40%;
	background-repeat: no-repeat;
	background-position: center;
}

.box .content .data {
	width: 50%;
}

.box .content .data .title {
	width: 100%;
	height: 26px;
	font-size: 22px;
	font-weight: 800;
}

.box .content .data .info {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 36px;
	padding: 0 20px;
	border-radius: 30px;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
	background-color: rgba(237,242,249,.2);
	border: 1px solid rgba(13,147,198,.13);
}
.box .content .data .info.yellow {background-color: #F7BD15;color: #fff;border: 0;font-weight: 600;}

.box .content .data .info .avatar {
	width: 60px;
	height: 100%;
	background-position: center -42px;
	background-repeat: no-repeat;
	margin-right: 10px;
}

.box form {width: 100%;position: relative;float: left;}

.box form button {
	position: absolute;
	width: auto;
	height: 48px;
	border-radius: 40px;
	background-color: #2EC044;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin: -24px 0 0 50%;
	transform: translateX(-50%);
	padding: 0 20px;
}

</style>