<template>
	<div class="subModal scroll">
		<form @submit.prevent="postForm()">
			<button>Salvar</button>
			<div class="">
				<label v-for="(b, i) in badges" :key="i">
					<input type="checkbox" @click="addBadge(b.id, $event)" hidden>
					<div class="badge tip" :class="{'active': this.BadgeSel.find((e) => e == b.id)}" :style="{backgroundImage: 'url('+b.image+')'}"></div>
				</label>
			</div>
		</form>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'MyBadges',
	components: {
	},
	data() {
		return {
			badges: [],
			BadgeSel: []
		}
	},
	methods:{
		async postForm(){
			const req = await axios.post(base+'page/sel-badges', {
				type: 'post',
				badges: this.BadgeSel
			})
			if(!req.data.error){
				this.$emit('close')
				this.$emit('reload')
			}
			this.$emit('textAlerta', req.data.text)
		},
		async getBadges(){
			const req = await axios.post(base+'page/sel-badges', {type: 'get'})
			this.badges = req.data
			for (let i = 0; i < req.data.length; i++) {
				if(req.data[i].active == true){
					this.BadgeSel.push(req.data[i].id)
				}
			}
		},
		addBadge(i){
			const idx = this.BadgeSel.findIndex((a) => a == i)
			
			if(idx >= 0){
				this.BadgeSel.splice(idx, 1)
			}else if(this.BadgeSel.length == 6){
				this.$emit('textAlerta', 'Limite atingido')
			}else if(idx == -1){
				this.BadgeSel.push(i)
			}
		}
	},
	mounted(){
		this.getBadges()
	}
}
</script>

<style scoped>

.subModal {
	height: 246px;
}

.subModal form {
	float: left;
	width: 100%;
	z-index: 9;
	position: relative;
}

.scroll {
	position: relative;
	width: 100%;
	height: 242px;
	overflow: auto;
	z-index: 0;
}

.scroll::-webkit-scrollbar{width: 4px;}
.scroll::-webkit-scrollbar-button { display: none; }
.scroll::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); }
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.3);
  width: 4px;
  border-radius: 4px;
}
.scroll::-webkit-scrollbar-thumb:horizontal {display: none}

.subModal .badges {
	position: relative;
	width: 100%;
	height: 190px;
}

.subModal .badge {
	float: left;
	position: relative;
	width: 54px;
	height: 54px;
	background-color: #eee;
	border-radius: 12px;
	background-position: center;
	background-repeat: no-repeat;
	margin: 8px 0 0 8px;
}
.subModal .badge.active {background-color: var(--color1);border-radius: 12px;}
</style>