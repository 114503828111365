import { createStore } from 'vuex'
import axios from 'axios';
import {base} from '../../var.js';

export default createStore({
  state: {
    initSite: {
      color1: '',
      color2: '',
      logo: '',
      header: '',
      logo_footer: '',
      favicon: '',
      root: document.documentElement,
      onSearch: '',
      maintenance: 0,
      clouds: Boolean
    },
    initUser: {
    },
    dev: false,
    logged: false,
    valCart: [],
    valCartView: [],
    textA: '',
    radio: {
      playing: true,
      name: '',
      programa: '',
      ouvintes: 0,
      volume: 60
    },
    audio: ''
  },
  getters: {
    totalValCalc: (state) => () => {
      let sm = 0
      state.valCart.forEach((e) => {
        sm = (e.item.preco * e.quantity) + sm
      });
      return sm;
    },
    qtyVal: (state) => (id) => {
      let foundCount = 0;
      
      state.valCart.forEach(
        (v) => Number(v.id) === Number(id) && foundCount++
      );
      return foundCount;
    },
  },
  mutations: {
    storeUser (state, data){
      state.initUser = data.user
      state.logged = data.logged
    },
    storeSite (state, data){
      state.initSite = data
    },
    storePlay(state, p){
      state.radio.playing = p
      if(p == true){ 
        state.audio.volume = 0.6
        state.radio.volume = 60
      }else{
        state.audio.volume = 0
        state.radio.volume = 0
      }
    },
    storeVolume(state, v){
      state.audio.volume = v
      state.radio.volume = v*100
    },
    addCalc(state, data){
      const idx = state.valCart.findIndex((i) => i.item.id == data.data.id)
      if(idx === -1){
          let f = data.qty == 1 ? 1 : data.qty
          let ins = {item: data.data, quantity: f}
          state.valCart.push(ins)
      }else{
        let f = (state.valCart[idx].quantity * data.qty) + 1
        if(f >= 0){
          state.valCart[idx].quantity = f
        }
      }
    },
    removeCalc (state, data) {
      const idx = state.valCart.findIndex((i) => i.item.id == data.id)
      let f = (state.valCart[idx].quantity * data.qty) - 1
      if(f >= 0){
        state.valCart[idx].quantity = f
      }
    },
    removeItemCalc(state, data) {
      // const idx = state.valCart.findIndex((i) => i.item.id == data.id)
      state.valCart.splice(data, 1)

    },
    clearVal(state){
      state.valCart = []
      state.valCartView = []
    },
    Alert(state, text){
      state.textA = text;
      setTimeout(() => {
        state.textA = '';
      }, 3500);
      // this.$store.commit('Alert', req.data.text)
    },
  },
  actions: {
    async player({state}){
			const req = await axios.get(base+"page/status");
      state.name = req.data.locutor
			state.radio.program = req.data.program
			state.radio.ouvintes = req.data.ouvintes
			// const app = document.getElementById('app');
			// this.box = `<audio id="audio" autoplay="" controls src="https://${req.data.ip}/${req.data.port}/stream" type="audio/mpeg" style="display: none;"></audio>`;
			// app.insertAdjacentHTML("beforeend", this.box)
			// document.getElementById("audio").play()

      state.audio = new Audio(`https://${req.data.ip}/${req.data.port}/stream`);

      state.audio.addEventListener("canplaythrough", () => {
      state.audio.play().catch(() => {
      window.addEventListener('click', () => {
      state.audio.play()
      }, { once: true })
      })
      });
		},
  },
  modules: {
  }
})
