<template>
    <div class="player" :class="{'float': posScroll > 500}">
        <div class="base">
            <div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+locutor+'&action=std&direction=2&head_direction=3&img_format=png&gesture=std&headonly=0&size=b&dance=0&frame_num=0&effect=0)'}"></div>
            <div class="mic"></div>
        </div>
        <div class="boxLoc">
            <span>{{this.locutor}}</span><br>
            <span class="s">com</span>&nbsp;
            <span>{{this.program}}</span>
        </div>
        <div class="ouvintes">
            <div class="btn play" @click="$store.commit('storePlay',!$store.state.radio.playing)">
                <i v-show="$store.state.radio.playing == true" class="fa-solid fa-pause"></i>
				<i v-show="$store.state.radio.playing == false" class="fa-solid fa-play"></i>
            </div>
            <div class="ouv">{{this.ouvintes}}</div>
            <div class="btn" @click="likeLoc()">
                <i v-if="this.likeIf == 'true'" class="fa-slid fa-heart"></i>
                <i v-else class="fa-regular fa-heart"></i>
            </div>
        </div>
        <div class="btn volume"><i class="fa-solid fa-volume-low"></i>
            <div class="in">
                <input type="range" name="volume" id="vol" max="100" min="0" step="1" v-model="$store.state.radio.volume" @change="rangeVol($event)">
            </div>
        </div>
        <div class="btn red" v-if="$store.state.logged" @click="this.showPed = !this.showPed"><i class="fa-solid fa-envelope-open"></i></div>
        <div class="btn pres" v-if="$store.state.logged" @click="this.showPre = !this.showPre"><i class="fa-solid fa-star"></i></div>
    </div>
    <Transition name="slide-fade">
        <ModPedido v-if="this.showPed" @close="this.showPed = !this.showPed"/>
    </Transition>
    <Transition name="slide-fade">
        <ModPresenca v-if="this.showPre" @close="this.showPre = !this.showPre"/>
    </Transition>
</template>

<script>
import axios from 'axios'
import {base} from '../../var.js'

import ModPedido from './modals/pedidos.vue'
import ModPresenca from './modals/presenca.vue'

export default {
    name: 'PlayerItem',
	components: {
        ModPedido,
        ModPresenca
	},
    emits: ['close'],
    data() {
        return {
            volume: 60,
            locutor: '',
            program: '',
			ouvintes: 0,
			playing: true,
			box: '',
            showPed: false,
            showPre: false,
            likeIf: false,
            audio: '',
            posScroll: 0
        }
    },
    methods: {
		async status(){
			const req = await axios.get(base+"page/status");
			
			this.locutor = req.data.locutor
			this.program = req.data.programa
			this.ouvintes = req.data.ouvintes
            this.likeIf = req.data.locLike

            this.$store.state.radio.name = req.data.locutor
            this.$store.state.radio.programa = req.data.programa
            this.$store.state.radio.ouvintes = req.data.ouvintes
		},
		rangeVol(e){
			const ele = e.target;

			let value = ele.value;
			ele.style.background = 'linear-gradient(to right, var(--color1) '+value+'%, var(--color1) ' + value + '%, rgba(36,78,102,.07) 0%, rgba(36,78,102,.07) 100%)'
			ele.setAttribute('value', this.volume)
			
			this.$store.commit('storeVolume', this.$store.state.radio.volume/100)
		},
        async likeLoc(){
            if(this.$store.state.logged == true){
                const req = await axios.get(base+'getItens/likeLoc')
                this.$store.commit('Alert', req.data.text)
            }else{
                this.$store.commit('Alert', 'Logue-se primeiro!')
            }
        }
	},
	mounted(){
        this.status();
		setTimeout(() => {
			this.status();
		}, 10000);

        addEventListener('scroll', () => {
            this.posScroll = window.scrollY
            // console.log(window.scrollY);
        })
	}
}
</script>

<style scoped>
.player {
    position: relative;
    top: 74px;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 68px;
    background-color: rgba(255,255,255,.9);
    border-radius: 80px;
    display: flex;
    justify-content: space-evenly;
    z-index: 99;
    margin-top: 10px;
}
.player.float {
    position: fixed;
    top: inherit;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: player .4s ease;
}
@keyframes player {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* .player::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 68px;
    background-color: var(--color1);
    z-index: 0;
} */

.player .base {
    position: relative;
    width: 73px;
    height: 74px;
    background-image: url(../assets/base-player.png);
    background-repeat: no-repeat;
    background-position: center 37px;
    z-index: 0;
}

.player .base .mic {
    position: relative;
    width: 37px;
    height: 55px;
    background-image: url(../assets/mic.png);
    background-repeat: no-repeat;
    margin: -57px 0 0 32px;
    z-index: 2;
}

.player .base .avatar {
    position: relative;
    width: 55px;
    height: 120px;
    margin-top: 11px;
    background-position: center;
    background-repeat: no-repeat;
    margin: -50px 0 0 6px;
    z-index: 1;
}

.player .boxLoc {
    width: auto;
    height: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 700;
    margin-top: 0;
    line-height: 18px;
    font-family: 'Poppins';
    padding-top: 16px;
}
.player .boxLoc span:first-of-type {width: 100%;}
.player .boxLoc span:last-of-type {font-weight: 600;font-size: 12px;}

.player .boxLoc .s {font-weight: 400;opacity: .7;font-weight: 12px;}

.player .ouvintes {
    width: auto;
    height: 46px;
    background-color: #fff;
    border-radius: 40px;
    margin-top: 12px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.player .ouvintes .btn {
    width: 40px;
    height: 100%;
    line-height: 46px;
    text-align: center;
}

.player .ouvintes .ouv {
    width: auto;
    height: 46px;
    background-color: #00A6D5;
    border-radius: 40px;
    font-size: 17px;
    font-weight: 800;
    color: #fff;
    line-height: 46px;
    padding: 0 24px;
}

.player > .btn {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, .15);
    border-radius: 40px;
    margin-top: 11px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}
.player > .btn.red {background-color: #E13762;}
.player > .btn.volume {background-color: #2EC044;}
.player > .btn.pres {background-color: #FFB320;}

.player .btn .in {
    position: relative;
    width: 150px;
    height: 20px;
    padding: 0 10px;
    background-color: rgba(0,0,0,.1);
    border-radius: 20px;
    margin: -68px 0 0 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
}
.player .btn.volume:hover .in {opacity: 1;visibility: visible;}

.player .btn .in input {
    float: left;
    width: 100%;
    height: 4px;
	-webkit-appearance: none;
	border-radius: 4px;
	background-color: rgba(36,78,102,.07);
	background: linear-gradient(to right, var(--color1) 60%, var(--color1) 60%, rgba(36,78,102,.5) 0%, rgba(36,78,102,.5) 100%);
    margin-top: 8px;
}

.player .btn .in input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	margin-top: 0px;
}

@media only screen and (max-width: 500px) {
    .player {
		display: none;
    }
}

</style>