<template>
	<div class="item rank">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title"><span>Ranking</span></div>
			</div>
			<div class="actions">
				<div class="select">
					<span>{{this.type}}&nbsp;<i class="fa-solid fa-angle-down"></i></span>
					<div class="boxSelect">
						<div class="option" @click="getRank(3, 'comments'), this.type = 'Comentários'">Comentários</div>
						<div class="option" @click="getRank(3, 'locs'), this.type = 'Locutores'">Locutores</div>
						<div class="option" @click="getRank(3, 'prom'), this.type = 'Promotores'">Promotores</div>
						<div class="option" @click="getRank(3, 'mark'), this.type = 'Marketing'">Marketing</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="ranking">
				<div class="box rank2" v-for="(r, index) in rank2" :key="index">
					<MyTip :title="r.user+'<br>'+r.count" top="-90%" line="24px"/>
					<div class="avatar" @click="showP = true, perfilName = r.user" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+r.user+'&action=std&direction=2&head_direction=2&img_format=png&gesture=std&headonly=0&size=b&dance=0&frame_num=0&effect=0)'}"></div>
				</div>
				<div class="box rank1" v-for="(r, index) in rank1" :key="index">
					<MyTip :title="r.user+'<br>'+r.count" top="-90%" line="24px"/>
					<div class="avatar" @click="showP = true, perfilName = r.user" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+r.user+'?&action=std&direction=2&head_direction=3&img_format=png&gesture=std&headonly=0&size=b&dance=0&frame_num=0&effect=0)'}"></div>
				</div>
				<div class="box rank3" v-for="(r, index) in rank3" :key="index">
					<MyTip :title="r.user+'<br>'+r.count" top="-90%" line="24px"/>
					<div class="avatar" @click="showP = true, perfilName = r.user" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+r.user+'&action=std,crr=&gesture=std&direction=4&head_direction=3&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
				</div>
			</div>
			<router-link :to="{path: '/pagina/ranking', name: 'Ranking'}">
				<div class="btn">Ver ranking completo</div>
			</router-link>
		</div>
		<Transition name="slide-fade">
			<ItemPerfil v-if="showP == true" @close="showP = false" :user="perfilName"/>
		</Transition>
	</div>
</template>

<script>
import { base } from '../../../var.js';
import axios from 'axios';
import MyTip from '../usefulness/tooltip.vue'
import ItemPerfil from '../modals/perfil.vue'

export default {
	name: 'RankItem',
	components: {
		MyTip,
		ItemPerfil
	},
	data() {
		return {
			rank1: [],
			rank2: [],
			rank3: [],
			type: 'Comentários',
			perfilName: '',
			showP: false
		}
	},
	methods: {
		async getRank(limit, type=''){
			const req = await axios.post(base+'getItens/getRank', {
				limit: limit,
				type: type
			})
			this.rank1 = req.data.rankTop[0]
			this.rank2 = req.data.rankTop[1]
			this.rank3 = req.data.rankTop[2]
		}
	},
	mounted(){
		this.getRank(3);
	}
}
</script>

<style scoped>

.rank {
    position: relative;
    width: 100%;
    padding: 10px 8px 0 0;
}

.rank .top .icon {
	background-color: #F7BD15;
	background-position: 5px -66px;
}

.rank .top .select {
	width: 120px;
	height: 34px;
	border-radius: 12px;
	color: #112535;
	font-size: 14px;
}

.rank .top .select span {
	width: 100%;height: 100%;float: left;line-height: 34px;text-align: center;
	background-color: #D7E0E9;
	border-radius: 12px;
}

.rank .top .select .boxSelect {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	background-color: #C8D2DC;
	border-radius: 12px;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease .1s;
	z-index: 99;
}
.rank .top .select:hover .boxSelect {opacity: 1;visibility: visible;}

.rank .top .select .boxSelect div {
	float: left;
	width: 100%;
	height: 32px;
	text-align: center;
	line-height: 32px;
	font-size: 14px;
	cursor: pointer;
}
.rank .top .select .boxSelect div:hover {font-weight: 600;}

.rank .content {
	position: relative;
	width: 100%;
	float: left;
	height: 280px;
	background-color: #E6EBF1;
	border-radius: 12px;
}

.rank .content .ranking {
	position: relative;
	float: left;
	width: 100%;
	height: calc(100% - 54px);
	display: flex;
	justify-content: space-evenly;
}

.rank .content .box {
	position: relative;
	width: 66px;
	height: 133px;
	background-repeat: no-repeat;
}

.rank .content .box.rank2 {background-image: url('../../assets/rank_2.png'); margin-top: 131px;}
.rank .content .box.rank1 {background-image: url('../../assets/rank_1.png'); margin-top: 93px;}
.rank .content .box.rank3 {background-image: url('../../assets/rank_3.png'); margin-top: 143px;}

.rank .content .box .avatar {
	float: left;
	width: 100%;
	height: 130px;
	margin: -96px 0 0 -3px;
	background-repeat: no-repeat;
	background-position: center;
}

.rank .content .btn {
	width: 100%;
	float: left;
	height: 54px;
	border-radius: 0 0 12px 12px;
	background-color: var(--color2);
	text-align: center;
	line-height: 54px;
	font-size: 14px;
	font-weight: 800;
	color: #fff;
}

</style>