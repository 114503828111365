<template>
	<div class="header">
		<div class="navbar">
			<NavBar/>
		</div>
		<div class="headerMain" :style="{backgroundImage: 'url('+this.header+')'}">
			<div class="clouds" id="clouds" v-show="$store.state.clouds"></div>
			<!-- <div class="headerBack" v-if="this.header == '' || this.header == null"></div> -->
			<div class="center">
				<div class="logo" :style="{backgroundImage: 'url('+this.logo+')'}"></div>
				<div class="buttons">
					<router-link :to="{path: '/pagina/valores', name: 'valores'}">
						<button><i class="fa-solid fa-chart-simple"></i>&nbsp;Valores</button>
					</router-link>
					<router-link :to="{path: '/pagina/recordes', name: 'recordes'}">
						<button class="yellow"><i class="fa-solid fa-cubes-stacked"></i>&nbsp;Recordes</button>
					</router-link>
				</div>
				<PlayerItem />
			</div>
			<div class="path left">
				<router-link :to="{path: '/', name: ''}">
					<button class="line"><i class="fa-solid fa-house"></i>&nbsp;Início</button>
				</router-link>
				<router-link :to="{path: '/comunidade', name: 'Comunidade'}">
					<button><i class="fa-solid fa-comment"></i>&nbsp;Timeline</button>
				</router-link>
			</div>
			<div>
			<KeepAlive>
				<Transition name="slide-fade">
					<component :is="activeComponent" @close="activeComponent = ''" :key="activeComponent"></component>
				</Transition>
			</KeepAlive>
			</div>
			<Transition name="slide-fade">
				<Alerta v-if="this.alert != ''" :text="this.alert" />
			</Transition>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import PlayerItem from './player.vue'
import { base } from '../../var.js'

import NavBar from './header/nav.vue'

// Modals
import ModRegister from './modals/register.vue'
import Alerta from './usefulness/alert.vue'
import ModForgot from './modals/forgot.vue'
import ModConfig from './modals/config.vue'
import ModArt from './modals/art.vue'

export default {
	name: 'HeaderItem',
	components: {
		PlayerItem,
		ModRegister,
		Alerta,
		ModForgot,
		ModConfig,
		ModArt,
		NavBar
	},
	emits: ['close'],
	props: ['logo', 'header'],
	data() {
		return {
			activeComponent: '',
			logged: false,
			user: '',
			pass: '',
			money: 0,
			pag_cat: [],
			alert: '',
			showMobile: false,
			headerTwo: '',
			searchClear: ''
		}
	},
	methods: {
		async setLogin (e) {
			e.preventDefault();
			const req = await axios.post(base+'page/login', {
				user: this.user,
				pass: this.pass
			})
			this.alert = req.data.text

			// this.$store.state.initUser.user = req.data.user
			// this.$store.state.initUser.logged = req.data.logged

			setTimeout(() => {
				this.alert = ''
			}, 3000);

			if(req.data.error == false){
				setTimeout(() => {
					document.location.reload(true);
				}, 2000);
			}

		},
		
		clouds(){
			let i = 0
			let c =  document.getElementById('clouds')
			setInterval(() => {
				c.style.backgroundPositionX = i+'px'
				i++
			}, 35);
		}
	},
	mounted() {
		this.clouds()
	}
}
</script>

<style scoped>

.header {
	position: relative;
	width: 100%;
	height: 510px;
	background-color: #2B577B;
	background-position: center;
	background-size: contain;
	overflow: hidden;
}

.header .navbar {
	position: relative;
	width: 100%;
	height: 80px;
	background-color: var(--color2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.11);
}

.header nav .boxLogin {
	width: 100px;
	height: 100%;
}

.header nav .boxLogin > button {
	float: right;
	width: auto;
	height: 44px;
	background-color: #2EC044;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border-radius: 22px;
	padding: 0 30px;
	margin-top: 16px;
	cursor: pointer;
	transition: top .3s ease;
}
.header nav .boxLogin:hover > button {border-bottom-right-radius: 0;}

.header nav .boxLogin .loginBox {
	position: relative;
	float: right;
	width: 240px;
	height: auto;
	border-radius: 10px 0 10px 10px;
	background-color: #fff;
	padding: 10px;
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease;
	z-index: 99;
}
.header nav .boxLogin:hover .loginBox {opacity: 1;visibility: visible;}
.header nav .boxLogin .loginBox.logged {padding: 0;padding-bottom: 10px;width: 220px;}

.header nav .boxLogin .loginBox input {
	float: left;
	width: 100%;
	height: 40px;
	background-color: #EFF2F5;
	border-radius: 10px;
	margin-bottom: 8px;
	padding: 10px
}

.header nav .boxLogin .loginBox button {
	float: left;
	width: auto;
	height: 36px;
	background-color: var(--color1);
	border-radius: 10px;
	color: #fff;
	font-size: 16px;
	padding: 0 18px;
	cursor: pointer;
}
.header nav .boxLogin .loginBox button.cor {background-color: #2EC044;margin-left: 8px;}

.header nav .boxLogin .loginBox .forgot {
	float: left;
	width: 100%;
	height: 30px;
	border-top: 2px solid #EBEBEB;
	text-align:center;
	font-size: 12px;
	color: var(--color1);
	line-height: 36px;
	margin-top: 8px;
	cursor: pointer;
}

.header nav .boxLogin .top {
	float: left;
	width: 100%;
	height: 86px;
	background-color: #EFF2F5;
	border-radius: 12px 12px 0 0;
	padding: 14px;
}

.header nav .boxLogin .top .nick {
	float: left;
	width: 100%;
	height: 20px;
	font-size: 14px;
	font-weight: 600;
	color: var(--color1);
	margin-top: 0px;
}

.header nav .boxLogin .top .money {
	float: left;
	width: auto;
	height: 30px;
	font-size: 14px;
	font-weight: 800;
	color: #fff;
	line-height: 30px;
	background-color: #244866;
	border-radius: 6px;
	padding: 0 10px;
	margin: 6px 0 0 -2px;
}

.header nav .boxLogin .top .money span {color: var(--color1);}

.header nav .boxLogin .top .logout {
	float: left;
	width: auto;
	height: 30px;
	font-size: 14px;
	color: var(--color1);
	line-height: 30px;
	background-color: rgba(0,0,0,.04);
	border-radius: 6px;
	padding: 0 10px;
	margin: 6px 0 0 6px;
}

.header nav .boxLogin .top .avatar {
	float: right;
	width: 56px;
	height: 86px;
	background-repeat: no-repeat;
	background-position: center;
	margin: -35px 0 0 0;
}

.header nav .boxLogin .logged button {
	float: left;
	width: calc(100% - 20px);
	height: 40px;
	margin: 10px 0 0 10px;
	font-size: 14px;
	background-color: #E4E9EE;
	color: #244866;
	line-height: 40px;
	font-weight: 600;
}

.header nav .boxLogin .logged button .icon {
	float: left;
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
}
.header nav .boxLogin .logged button .icon.parte {background-image: url('../assets/icons.png');background-position: -66px -92px;}
.header nav .boxLogin .logged button .icon.conf {background-image: url('../assets/icons.png');background-position: -63px -57px;}
.header nav .boxLogin .logged button .icon.custom {font-size: 18px;text-align: center;line-height: 40px;margin-left: 3px;}

.headerMain {
	position: relative;
	height: calc(100% - 80px);
	background-position: center;
	/*background-size: contain; */
}

.headerMain .clouds {
	position: absolute;
	top: 20px;
	left: 0;
	width: 100%;
	height: 65%;
	background-image: url(../assets/clouds.png);
}

.header .logo {
	position: relative;
	float: left;
	width: 50%;
	height: 200px;
	background-repeat: no-repeat;
	background-position: 0 center;
	/* background-size: contain; */
	margin: 70px 0 0 60px;
	z-index: 1;
}

.header .headerBack {
	position: absolute;
	right: 0;
	top: 106px;
	width: 657px;
	height: 325px;
	background-image: url('../assets/headerBack.png');
}

.header .headerImg {
	position: relative;
	top: 106px;
	width: 100%;
	height: auto;
}


.header .headerBot {
	position: relative;
	top: 20px;
	width: 100%;
	height: 68px;
}

.header .buttons {
	position: absolute;
	right: 0;
	top: 80px;
	width: 20%;
	height: 170px;
	display: grid;
	align-content: space-evenly;
    justify-items: end;
}

.header .buttons button {
	width: auto;
	height: 56px;
	border-radius: 34px 12px 12px 34px;
	background-color: #FF4848;
	padding: 0 20px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	box-shadow: 0 2px 3px rgba(0,0,0,.1);
}
.header .buttons button.yellow {background-color: #FFB320;}

.header .headerBot .search {
	width: 25%;
	margin-top: 20px;
}

.header .headerBot .search i {
	float: left;
	font-size: 14px;
	color: #fff;
	margin-top: 11px;
}

.header .headerBot .search input {
	float: left;
	width: 90%;
	height: 36px;
	background-color: rgba(255, 255, 255, 0.11);
	border-radius: 20px;
	margin: 0px 0 0 14px;
	padding: 10px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
}
.header .headerBot .search input::placeholder {color: #fff;}

.path {
	position: fixed;
	bottom: 0;
	width: auto;
	height: 60px;
	background-color: var(--color2);
	border-radius: 20px 20px 0 0;
	color: #fff;
	font-size: 14px;
	z-index: 99;
}
.path.left {left: 5%;}
.path.right {right: 5%;}

.path button {
	width: auto;
	height: 100%;
	padding: 0 24px;
}

.path button.line {border-right: 1px solid rgba(255, 255, 255, 0.3);}

.btnMobile {display: none;}

@media only screen and (max-width: 500px) {

	.header .logo {
		margin: 50% 0 0 50% !important;
		transform: translate(-50%, -50%) !important;
	}
	
	.header .buttons {
		position: absolute;
		top: 260px;
		right: 28px;
		white-space: nowrap;
	}
	
	.header .headerBot .search {display: none;}

	.header .logo {
		margin: 34px 0 0 50%;
		transform: translateX(-50%);
	}

}
</style>