<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="content">
				<form @submit.prevent="getLogin">
					<span>Login</span>
					<div class="avatar" :style="{background: user == '' ? '' : 'url(https://imager.radiohabblet.com.br/?user='+user+'&action=std,crr=&gesture=std&direction=2&head_direction=2&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30&size=l) center -64px'}">
						<div class="white"></div>
					</div>
					<input type="text" name="user" placeholder="Usuário" v-model="user">
					<input type="password" name="pass" placeholder="Senha" v-model="pass">
					<div class="forgot" @click="$emit('forgot')">Esqueceu a senha?</div>
					<button type="submit">Entrar</button>
					<div @click="$emit('register')" class="blue btn">Criar conta</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'ModLogin',
	data() {
		return {
			user: '',
			pass: '',
		}
	},
	methods: {
		async getLogin(){
			const req = await axios.post(base+'page/login', {
				user: this.user,
				pass: this.pass
			})
			this.$store.commit('Alert', req.data.text)

			if(req.data.error == false){
				this.$emit('close')
				this.$store.commit('storeUser', {user : req.data.user_data, logged: true})
			}
		}
	}
}
</script>

<style scoped>

.box {
	width: 300px !important;
	background-color: rgb(236, 241, 235) !important;
	border-radius: 20px !important;
}

.content form {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.content form > span {
	width: 100%;
	height: 30px;
	font-size: 20px;
	font-weight: 800;
	text-align: center;
	margin-top: 10px;
	background: -webkit-linear-gradient(rgba(46,192,68,1), rgba(194,215,51,1));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.content form .avatar {
	width: 100%;
	height: 140px;
	background: url(../../assets/user.png) center 5px;
	background-repeat: no-repeat;
	margin-top: 30px;
}

.content form .avatar .white {
	width: 100%;
	height: calc(100% + 1px);
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(236, 241, 235) 100%);
}

.content form input {
	width: 250px;
	background-color: #fff;
	border-radius: 40px;
	text-align: center;
}

.content form .forgot {
	width: 100%;
	text-align: center;
	font-size: 12px;
	font-weight: 800;
	color: #000;
	font-style: italic;
	margin-top: 10px;
}

.content form button,
.content form .btn {
	width: 250px;
	height: 46px;
	padding: 0 20px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	line-height: 46px;
	font-weight: 700;
	border-radius: 40px;
	background: rgb(46,192,68);
	background: linear-gradient(90deg, rgba(46,192,68,1) 0%, rgba(194,215,51,1) 100%);
	margin: 10px 0 0 0;
	transform: none;
}
.content form .btn.blue {background: #0D93C6 ;cursor: pointer;}
</style>