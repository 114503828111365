<template>
	<div class="item podcast">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title"><span>PodCast</span></div>
			</div>
			<div class="actions">
				<button :disabled="prev <= 0" @click="getPod(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled="next > total" @click="getPod(this.next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
		</div>
		<div class="content">
			<div class="box" v-for="p in pod" :key="p.id" :style="{backgroundImage: 'url('+p.imagem+')'}" v-show="pod.length > 0">
				<div class="data">
					<div class="info">
						<div class="span">
							<span class="nobr">{{p.title}}</span><br>
							<span>{{p.description}}</span>
						</div>
						<div class="play"><i @click="getPlay(p.audio, !playing, $event)" class="fa-solid fa-play"></i></div>
					</div>
				</div>
				<audio controls id="podcast" hidden></audio>
			</div>
		</div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

export default {
	name: 'PodItem',
	data(){
		return{
			pod: [],
			prev: 0,
			next: 0,
			total: 0,
			disabledP: true,
			disabledN: true,
			playing: false
		}
	},
	methods: {
		async getPod(pag=1){
			const req = await axios.post(base+'getItens/podcast', {
				pag: pag
			})
			this.pod = req.data.podcast
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total

			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		},
		getPlay(a, p, e){
			
			let idAudio = document.getElementById('podcast')
			
			if(idAudio.childNodes[0] == null){
				let src = `<source src="${a}" type="audio/mpeg">`;
				idAudio.innerHTML = src
				idAudio.play()

				let icon = e.target
				icon.classList.remove("fa-play");
				icon.classList.add("fa-pause");
			}else{
				let icon = e.target
				this.playing = !this.playing;
				if(p == true){
					icon.classList.remove("fa-pause");
					icon.classList.add("fa-play");
					idAudio.pause();
				}else{
					icon.classList.remove("fa-play");
					icon.classList.add("fa-pause");
					idAudio.play();
				}
			}	
				
		}
	},
	mounted(){
		this.getPod();
	}
}
</script>

<style scoped>
.podcast {
	float: left;
	width: calc(50% - 10px);
	margin: 40px 0 0 20px;
}

.podcast .top .icon {
	background-position: -34px 4px;
	background-color: #101D27;
}

.podcast .content {
	float: left;
	width: 100%;
	height: 280px;
	background-color: #fff;
	border-radius: 12px;
	padding: 10px;
	overflow: hidden;
}

.podcast .box {
	float: left;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 12px;
}

.podcast .box .data {
	float: left;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	background-color: rgba(255, 255, 255, .82);
	margin: 1px;
	border-radius: 12px;
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease;
}
.podcast .box:hover .data {opacity: 1;visibility: visible;}

.podcast .box .data > div {
	margin-top: 190px;
	float: left;
	width: 100%;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
}

.podcast .box .data > div .span {
	float: left;
	width: calc(100% - 70px);
}

.podcast .box .data > div .span span {
	font-size: 14px;
}
.podcast .box .data > div .span span:first-of-type {font-weight: 600;}
.podcast .box .data > div .span span:last-of-type {height: 37px;overflow: hidden;}

.podcast .box .data > div .play {
	width: 60px;
	height: 60px;
	float: left;
	margin-left: 10px;
	background-color: #2BAB3E;
	border-radius: 60px;
	color: #fff;
	line-height: 62px;
	text-align: center;
	cursor: pointer;
}
.podcast .box .data > div .play i {width: 100%;height: 100%;}
</style>