<template>
	<div class="item shop">
		<div class="top">
			<div>
				<div class="icon"></div>
				<div class="title"><span>RHShop</span></div>
			</div>
			<div class="actions">
				<input type="text" name="search" placeholder="Pesquisar" v-model="search">
                <button><i class="fa-solid fa-magnifying-glass"></i></button>
			</div>
		</div>
		<div class="tenda"></div>
		<div class="content">
			<Swiper  :slidesPerView="limit" :spaceBetween="10" :pagination="{ clickable: false, }" class="mySwiper">
				<swiper-slide class="box animate__animated animate__headShake" v-for="i in filterStore" :key="i.id">
					<div class="info">
						<div class="price"><i class="fa-solid fa-coins"></i>&nbsp;{{i.price}}</div>
						<div class="img" :style="{backgroundImage: 'url('+i.image+')'}"></div>
						<div class="name nobr">{{ i.name }}</div>
						<div class="sold nobr" v-show="i.quantity == 0"><div>SOLD OUT</div></div>
					</div>
					<div class="btn" @click="modC = true, modO = i">Comprar</div>
				</swiper-slide>
			</Swiper>
		</div>
		<Transition name="slide-fade">
			<ItemBuy v-if="modC" :item="modO" @close="modC = false"/>
		</Transition>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'
import ItemBuy from '../modals/buyShop.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
	name: 'ShopItem',
	components: {
		ItemBuy,
		Swiper,
		SwiperSlide
	},
	emits: ['close'],
	data(){
		return {
			itens: [],
			modC: false,
			modO: [],
			search: '',
			limit: 3
		}
	},
	computed: {
		filterStore(){
			return this.itens.filter((i) =>
				i.name.toLowerCase().includes(this.search)
			);
		}
	},
	methods:{
		async getItem(){
			const req = await axios.get(base+'content/store/get')
			this.itens = req.data.store
		}
	},
	beforeMount(){
		if(navigator.userAgentData.mobile){
			this.limit = 1
		}
	},
	mounted(){
		this.getItem();
	}
}
</script>

<style scoped>

.shop {margin-top: 36px;}

.shop .top .icon {
	background-color: #2FC145;
	background-position: 4px -29px;
}

.shop .content {
	position: relative;
	width: 100%;
	height: 270px;
	margin-top: 20px;
	background-color: #E6EBF1;
	padding: 74px 0 18px 10px;
	overflow: auto;
	margin-top: 30px;
	z-index: 0;
	border-radius: 12px;
}

.shop .tenda {
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
	width: 853px;
	height: 62px;
	background-image: url(../../assets/tenda.png);
	background-repeat: no-repeat;
	background-position: center 0;
	z-index: 1;
}

.mySwiper .swiper-slide {
	margin-right: 14px !important;
}

.shop .content .box {
	position: relative;
	max-width: 204px;
	height: 178px;
	background-color: #fff;
	border-radius: 12px;
	transition: all .3s ease;
}

.shop .content .box .price {
	position: absolute;
	top: 10px;
	left: 10px;
	width: auto;
	height: 32px;
	border-radius: 12px;
	background-color: #F7BD15;
	padding: 0 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	line-height: 32px;
}

.shop .content .box .img {
	width: 100%;
	height: 142px;
	background-repeat: no-repeat;
	background-position: center;
}

.shop .content .box .name {
	position: absolute;
	bottom: 36px;
	left: 0;
	width: 100%;
	height: 40px;
	color: var(--color1);
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 40px;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 45%);
}

.shop .content .box .btn {
	width: 100%;
	height: 36px;
	background-color: var(--color1);
	border-radius: 0 0 12px 12px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	text-align: center;
	line-height: 36px;
	user-select: none;
}

.shop .content .box .sold {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,.5);
	border-radius: 12px;
}

.shop .content .box .sold div {
	position: absolute;
	left: 50%;
	top: 50%;
	width: auto;
	height: 34px;
	background-color: #D62B2B;
	color: #fff;
	font-weight: 800;
	font-size: 16px;
	padding: 0 8px;
	border-radius: 12px;
	line-height: 34px;
	user-select: none;
	transform: translate(-50%, -50%) rotateZ(350deg);
}

@media only screen and (max-width: 500px) {
	.shop .tenda {transform: scale();}
}
</style>