<template>
	<div class="modal presenca">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Marcar presença</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="setPre">
					<input type="text" name="text" placeholder="Digite o código aqui..." minlength="1" v-model="presenca">
					<button>Marcar</button>
				</form>
			</div>
		</div>
		<Alerta v-if="textA != ''" :text="this.textA" />
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModPresenca',
	components: {
		Alerta
	},
	data(){
		return {
			presenca: '',
			textA: ''
		}
	},
	methods: {
		async setPre(){
			const req = await axios.post(base+'page/presenca', {
				text: this.presenca
			})
			this.textA = req.data.text

			setTimeout(() => {
				this.textA = ''
				this.presenca = ''
			}, 3000);
		}
	}
}
</script>

<style scoped>
.presenca .top {height: 50px;}
</style>