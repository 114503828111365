<template>
	<nav>
		<button class="btnMobile" @click="this.showMobile = !this.showMobile" :class="{active: this.showMobile == true}"><i class="fa-solid fa-bars"></i></button>
		<ul class="nav" :class="{active: this.showMobile}">
			<li class="liMain small">
				<router-link :to="{path: '/', name: ''}" @click="showMobile = false">
					<p>Início</p>
				</router-link>
			</li>
			<li class="liMain" v-for="(pc, i) in cats" :key="i">
				<p>{{pc.cat}}&nbsp;<i class="fa-solid fa-sort-down"></i></p>
				<div class="subNav">
					<ul>
						<li v-for="(p, index) in pc.paginas" :key="index">
							<router-link :to="{path:  `/pagina`, name: p.url, params: {id: p.id, url: p.url}}" @click="showMobile = false">
								<span>{{p.title}}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</li>
			<li class="liMain small">
				<p>API&nbsp;<i class="fa-solid fa-sort-down"></i></p>
				<div class="subNav">
					<ul>
						<li>
							<router-link :to="{path: '/api', name: 'HabbletApi'}" @click="showMobile = false">
								<span>Emblemas/Mobis</span>
							</router-link>
						</li>
						<li>
							<router-link :to="{path: '/pagina/imager', name: 'imager'}" @click="showMobile = false">
								<span>Habblet Imager</span>
							</router-link>
						</li>
						<li>
							<router-link :to="{path: '/profile-api', name: 'ProfileApi'}" @click="showMobile = false">
								<span>Perfil Habblet</span>
							</router-link>
						</li>
					</ul>
				</div>
			</li>
		</ul>
		<div class="right">
			<div class="radio-mod">
				<div class="avatar" @click="modRd = true" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+$store.state.radio.name+'&action=std,crr=&gesture=std&direction=4&head_direction=2&headonly=true&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
				<div class="name" @click="modRd = true">Rádio&nbsp;<i id="playing" class="fa-solid fa-caret-down"></i></div>
				<button @click="$store.commit('storePlay', !$store.state.radio.playing)">
					<i v-show="$store.state.radio.playing == true" class="fa-solid fa-pause"></i>
					<i v-show="$store.state.radio.playing == false" class="fa-solid fa-play"></i>
				</button>
			</div>
			<div class="boxLogin" v-if="$store.state.logged == 'false' || $store.state.logged == false">
				<button @click="modL = true">Entrar</button>
			</div>
			<div class="boxLogin" v-else>
				<button @click="modU = true">{{ $store.state.initUser.user }}</button>
			</div>
		</div>
		<Transition name="slide-fade">
			<ModLogin v-if="modL" @close="modL = false" @register="modR = true, modL= false" @forgot="modF = true, modL = false"/>
		</Transition>
		<Transition name="slide-fade">
			<ModRegister v-if="modR" @close="modR = false"/>
		</Transition>
		<Transition name="slide-fade">
			<ModUser v-if="modU" @close="modU = false" />
		</Transition>
		<Transition name="slide-fade">
			<ModRadio v-if="modRd" @close="modRd = false" />
		</Transition>
		<Transition name="slide-fade">
			<ModForgot v-if="modF" @close="modF = false"/>
		</Transition>
	</nav>
</template>

<script>
import axios from 'axios'
import { base } from '../../../var.js'
import ModLogin from '../modals/login.vue'
import ModRegister from '../modals/register.vue'
import ModUser from '../modals/logged-menu.vue'
import ModRadio from '../modals/radio'
import ModForgot from '../modals/forgot.vue'

export default {
	name: 'NavBar',
	components: {
		ModLogin,
		ModRegister,
		ModUser,
		ModRadio,
		ModForgot
	},
	data() {
		return {
			showMobile: false,
			cats: [],
			modL: false,
			modR: false,
			modF: false,
			modU: false,
			modRd: false
		}
	},
	methods: {
		async getPagCat(){
			const req = await axios.get(base+'getItens/pag_cat');
			this.cats = req.data
		},
	},
	mounted(){
		this.getPagCat()
	}
}
</script>

<style scoped>

nav {
	position: relative;
	width: var(--width);
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	z-index: 999;
}

nav .nav {
	width: 65%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

nav .nav .liMain {
	position: relative;
	width: 160px;
	height: 100%;
	transition: all .4s ease;
	top: 0px;
	left: 0;
}
nav .nav .liMain:hover {box-shadow: inset 0 -80px 0 0 rgba(13, 147, 198, .2);}
nav .nav .liMain.small {width: 100px;}
/* nav .nav .liMain:hover {background: rgb(33,66,93);background: linear-gradient(180deg, rgba(33,66,93,0) 0%, rgba(13,147,198,.2) 100%);} */

nav .nav .liMain p {
	position: relative;
	width: 100%;
	text-align: center;
	line-height: 80px;
	user-select: none;
	font-size: 16px;
	color: #fff;
	font-weight: 600;
}
nav .nav .liMain:hover p {color: var(--color1);}

nav .nav .liMain p i {
	position: relative;
	top: -3px;
}

nav .nav .liMain .subNav {
	position: absolute;
	left: 50%;
	top: 70px;
	width: 200px;
	height: auto;
	background-color: rgb(252, 252, 253);
	border-radius: 20px;
	padding: 6px;
	opacity: 0;
	transition: all .4s ease-in-out;
	visibility: hidden;
	z-index: 99;
	transform: translateX(-50%);
}
nav .nav .liMain:hover .subNav {opacity: 1;height: auto;visibility: visible;}
nav .nav .liMain .subNav::after {
	content: '';
	position: absolute;
	top: -6.5px;
	left: 50%;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 6.5px 6px 6.5px;
	border-color: transparent transparent rgb(250, 250, 250) transparent;
	transform: translateX(-50%);
}

nav .nav .liMain .subNav ul li {
	width: 100%;
	height: 40px;
	border-radius: 40px;
	background-color: rgba(255,255,255,1);
	margin-top: 4px;
}
nav .nav .liMain .subNav ul li:hover {background-color: rgba(255,255,255,.4);}

nav .nav .liMain .subNav ul li span {
	float: left;
	width: 100%;
	height: 100%;
	font-size: 14px;
	text-align: center;
	line-height: 40px;
	padding: 0 16px;
}

.boxLogin button {
	width: auto;
	height: 56px;
	border-radius: 50px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	background: rgb(46,192,68);
	background: linear-gradient(90deg, rgba(46,192,68,1) 0%, rgba(194,215,51,1) 100%);
	padding: 0 40px;
	margin-top: 12px;
	cursor: pointer;
}

nav .right {
	width: 35%;
	display: flex;
	justify-content: space-between;
}

nav .right .radio-mod {
	width: auto;
	height: 56px;
	background-color: rgba(255,255,255,.15);
	border-radius: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	margin-top: 12px;
}

nav .right .radio-mod .avatar {
	width: 56px;
	height: 100%;
	background-position: center -26px;
	background-repeat: no-repeat;
}

nav .right .radio-mod .name {
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	margin-left: 10px;
}

nav .right .radio-mod button {
	width: 60px;
	height: 40px;
	border-radius: 20px;
	color: #000;
	background-color: rgba(255,255,255,.7);
	margin-left: 16px;
}

.btnMobile {display: none;}

@media only screen and (max-width: 500px) {
	.btnMobile {
		position: absolute;
		top: 16px;
		left: 20px;
		width: 45px;
		height: 45px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 0px 6px rgba(0,0,0,.15);
		display: block;z-index: 9999;
		font-size: 14px;
	}
	.btnMobile.active {position: fixed;}
	.header nav {
		display: block;
		width: 100%;
	}
	.header nav .nav {display: none;}
	.header nav .nav.active {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9998;
		background-color: #fff;
		display: block;
	}

	
	.header nav .nav .liMain {
		position: relative;
		width: 100%;
		height: 80px;
	}
	.header nav .nav .liMain p {color: #444;float: left;}

	.header nav .nav .liMain .subNav {
		display: none;
		float: left;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		border-radius: 0;
		background-color: rgb(255, 255, 255);
		transform: none;
	}
	.header nav .nav .liMain:hover .subNav {display: block;}

	nav .right {
		width: 100%;justify-content: flex-end;
	}

	.right .boxLogin {margin-left: 10px;}
}
</style>