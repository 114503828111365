<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Postar na galeria</div>
				<div class="subTitle">Leia as regras antes de postar!</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit="setArt">
					<input type="text" name="title" placeholder="Título" v-model="title">
					<input type="text" name="description" placeholder="Descrição" v-model="description">
					<input type="file" name="img" class="file" accept="image/*" id="file" ref="file" @change="uploadfile()">
					<button>Confirmar</button>
				</form>
			</div>
		</div>
	</div>
	<Alerta v-if="this.textalert != ''" :text="this.textalert" />
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModArt',
	components: {
		Alerta
	},
	data(){
		return {
			title: '',
			description: '',
			file: '',
			textalert: ''
		}
	},
	methods: {
		uploadfile() {
			this.file = this.$refs.file.files[0];
		},
		async setArt(e){
			e.preventDefault();
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('title', this.title);
			formData.append('description', this.description);

			const req = await axios.post(base+'page/postArt', 
				formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
			})
			this.textalert = req.data.text
			setTimeout(() => {
				this.textalert = ''
			}, 3000);
			if(req.data.href){
				this.$emit('close');
				this.$router.push({name: 'Arte', params: {url: req.data.url, id: req.data.id}});
			}
		}
	}
}
</script>

<style scoped>
.content .file {
	background-color: var(--color1);
	color: #fff;
	font-size: 12px;
	font-weight: 600;
}
</style>