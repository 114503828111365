<template>
	<div class="rank-f item">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title"><span>Ranking</span> do fórum</div>
			</div>
			<div class="actions">
				<button :disabled='prev == 0' @click="getRank(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled='next > total' @click="getRank(this.next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
		</div>
		<div class="content">
			<div class="box animate__animated animate__headShake" v-for="(r, i) in rank" :key="i">
				<div class="box-avatar">
					<div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+r.user+'&action=&gesture=std&direction=3&head_direction=2&headonly=true&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
				</div>
				<div class="data">
					<span class="nick" @click="perfilName = r.user, showP = true">{{r.user}}</span>
					<span>{{r.count}} comentários</span>
				</div>
				<div class="rank" :class="{gold: r.rank == 1, silver: r.rank == 2, bronze: r.rank == 3}">{{r.rank}}</div>
			</div>
		</div>
		<Transition name="slide-fade">
			<ItemPerfil v-if="showP == true" @close="showP = false" :user="perfilName"/>
		</Transition>
	</div>
</template>

<script>
import axios from 'axios';
import { base } from '../../../var.js';
import ItemPerfil from '../modals/perfil.vue'

export default {
	name: 'ItemRankCom',
	components: {
		ItemPerfil,
	},
	data() {
		return {
			rank: [],
			disableP: false,
			disableN: false,
			showP: false,
			perfilName: '',
			next: 0,
			prev: 0,
			total: 0
		}
	},
	methods: {
		async getRank(p=1){
			const req = await axios.post(base+'getItens/rank-comments', {
				pag: p
			})
			this.rank = req.data.comments
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
		}
	},
	mounted(){
		this.getRank()
	}
}
</script>

<style scoped>

.rank-f {
    position: relative;
    width: 100%;
    padding: 10px 8px 0 0;
}

.rank-f .top .icon {
	background-image: url('../../assets/icon5.png');
	background-position: 58% 45%;
	background-color: #37758D;
}

.rank-f .content {
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 12px;
	padding: 0px 10px 4px 10px;
}

.rank-f .box {
	width: 100%;
	height: 64px;
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
}

.rank-f .box .box-avatar {
	width: 64px;
	height: 64px;
	background-color: #F8FAFC;
	border-radius: 12px;
}

.rank-f .box .box-avatar .avatar {
	width: 100%;
	height: 100%;
	background-position: 50% 30%;
	background-repeat: no-repeat;
}

.rank-f .box .data {
	width: 170px;
	height: 100%;
}

.rank-f .box .data span {
	float: left;
	width: 100%;
	height: 20px;
	line-height: 20px;
	margin-top: 2px;
	font-size: 13px;
	font-weight: 500;
	color: #949EA9;
}

.rank-f .box .data span.nick {
	font-size: 15px;
	font-weight: 600;
	color: var(--color1);
	margin-top: 12px;
}

.rank-f .box .rank {
	width: 32px;
	height: 32px;
	background-color: var(--color2);
	color: #fff;
	border-radius: 12px;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	line-height: 32px;
	user-select: none;
	margin-top: 15px;
}
.rank-f .box .rank.gold {background-color: #F7BD15;}
.rank-f .box .rank.silver {background-color: #A8A8A8;}
.rank-f .box .rank.bronze {background-color: #E56841;}
</style>