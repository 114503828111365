<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="avatar">
				<div class="av" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+$store.state.radio.name+'&action=std,crr=&gesture=sml&direction=2&head_direction=2&headonly=false&size=l&img_format=&dance=&effect=&frame_num=30)'}"></div>
				<div class="base"></div>
				<div class="mic"></div>
			</div>
			<div class="data">
				<div class="name">{{ $store.state.radio.name }}</div>
				<div class="program">com <span>{{ $store.state.radio.programa }}</span></div>
				<div class="buttons">
					<div class="btn volume">
						<i class="fa-solid fa-volume-low"></i>
						<div class="in">
							<input type="range" name="volume" id="vol" max="100" min="0" step="1" v-model="$store.state.radio.volume" @change="rangeVol($event)">
						</div>
					</div>
					<div class="btn star" @click="modS = true"><i class="fa-solid fa-star"></i></div>
					<div class="btn msg" @click="modM = true"><i class="fa-solid fa-envelope"></i></div>
					<div class="btn ouv">{{ $store.state.radio.ouvintes }}</div>
				</div>
			</div>
		</div>
		<Transition name="slide-fade">
			<ModPresenca v-if="modS" @close="modS = false"/>
		</Transition>
		<Transition name="slide-fade">
			<ModPedidos v-if="modM" @close="modM = false"/>
		</Transition>
	</div>
</template>

<script>
import ModPresenca from '../modals/presenca.vue'
import ModPedidos from '../modals/pedidos.vue'

export default {
	name: 'ModRadio',
	components: {
		ModPresenca,
		ModPedidos
	},
	data() {
		return {
			modS: false,
			modM: false
		}
	},
}
</script>

<style scoped>

.box {
	height: 180px !important;
	display: flex;
	justify-content: space-between;
	border: 20px !important;
	background-color: rgba(255,255,255,.9) !important;
	padding: 0 20px;
}

.box .avatar {
	position: relative;
	width: 42%;
	height: 100%;
}

.box .avatar .av {
	position: relative;
	width: 100%;
	height: 100%;
	background-position: center -68px;
	background-repeat: no-repeat;
	z-index: 1;
}

.box .avatar .base {
	position: absolute;
	top: 118px;
	left: 10px;
	width: 146px;
	height: 62px;
	background-image: url(../../assets/base-large.png);
	background-repeat: no-repeat;
	z-index: 0;
}

.box .avatar .mic {
	position: absolute;
	right: 10px;
	bottom: 0;
	width: 74px;
	height: 110px;
	background-image: url(../../assets/mic-large.png);
	background-repeat: no-repeat;
	z-index: 2;
}

.box .data {
	width: 55%;
	height: 100%;
	padding: 10px 0;
}

.box .name {
	width: 100%;
	height: 20px;
	font-size: 16px;
	font-weight: 600;
	color: #444;
	margin-top: 10px;
}

.box .data .program {
	width: 100%;
	height: 20px;
	font-size: 13px;
	font-weight: 300;
}

.box .data .program span {
	font-weight: 600;
}

.box .data .buttons {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 10px;
}

.box .data .buttons .btn {
	position: relative;
	width: auto;
	height: 40px;
	font-size: 15px;
	line-height: 40px;
	border-radius: 20px;
	background-color: #fff;
	padding: 0 20px;
}

.box .data .buttons .btn.volume {color: #2EC044;}
.box .data .buttons .btn.star {color: #FFB320;}
.box .data .buttons .btn.msg {color: #E13762;}
.box .data .buttons .btn.ouv {
	width: 100%;
	background-color: #00A6D5;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin-top: 8px;
}


.box .btn .in {
	position: absolute;
	width: 150px;
	height: 20px;
	padding: 0 10px;
	background-color: rgba(0,0,0,.1);
	border-radius: 20px;
	margin: -62px 0 0 25%;
	transform: translateX(-50%);
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
}
.box .volume:hover .in {opacity: 1;visibility: visible;}

.box .volume .in input {
	position: relative;
	top: -13px;
	width: 100%;
	height: 4px;
	-webkit-appearance: none;
	border-radius: 4px;
	background-color: rgba(36,78,102,.07);
	background: linear-gradient(to right, var(--color1) 60%, var(--color1) 60%, rgba(36,78,102,.5) 0%, rgba(36,78,102,.5) 100%);
}

.box .volume .in input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	margin-top: 0px;
}

</style>