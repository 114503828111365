<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="content">
				<form @submit.prevent="setFor">
					<span class="title">Recuperar conta</span>
					<div class="avatar" :style="{background: user == '' ? '' : 'url(https://imager.radiohabblet.com.br/?user='+user+'&action=std,crr=&gesture=std&direction=2&head_direction=2&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30&size=l) center -64px'}">
						<div class="white"></div>
					</div>
					<input type="text" name="user" placeholder="Usuário" v-model="this.user">
					<input type="password" name="pass" placeholder="Senha" v-model="this.pass">
					<input type="password" name="passr" placeholder="Repita a senha" v-model="this.passr">
					<span class="code">Coloque <span @click="copy($event)">{{ code }}</span> em sua missão!</span>
					<button>Recuperar</button>
				</form>
			</div>
		</div>
		<Transition name="slide-fade">
			<Alerta v-if="this.alert != ''" :text="this.alert" />
		</Transition>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'
import Alerta from '../usefulness/alert.vue'

export default {
	name: 'ModForgot',
	components: {
		Alerta
	},
	data(){
		return {
			user: '',
			pass: '',
			passr: '',
			code: '',
			nick: false,
			alert: ''
		}
	},
	methods:{
		async getCode() {
			const req = await axios.get(base+'getItens/init')
			this.code = req.data.code
		},
		async setFor() {
			const req = await axios.post(base+'page/forgot', {
				user: this.user,
				pass: this.pass,
				passr: this.passr,
			})

			this.$store.commit('Alert', req.data.text)
		
		}
	},
	created() {
		this.getCode();
	}
}
</script>

<style scoped>

.box {
	width: 300px !important;
	background-color: rgb(236, 241, 235) !important;
	border-radius: 20px !important;
}

.content form {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}


.content form  .title {
	width: 100%;
	height: 30px;
	font-size: 20px;
	font-weight: 800;
	text-align: center;
	margin-top: 10px;
	background: -webkit-linear-gradient(#0D93C6, #35B6FF);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.content form .avatar {
	width: 100%;
	height: 140px;
	background: url(../../assets/user.png) center 5px;
	background-repeat: no-repeat;
	margin-top: 30px;
}

.content form .avatar .white {
	width: 100%;
	height: calc(100% + 1px);
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(236, 241, 235) 100%);
}

.content form input {
	width: 250px;
	background-color: #fff;
	border-radius: 40px;
	text-align: center;
}

.content form .code {
	font-size: 14px;
	margin-top: 10px;
	font-weight: 500;
}

.content form .code span {
	font-weight: 600;
	color: #2EC044;
}

.content form button {
	width: 250px;
	height: 46px;
	padding: 0 20px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	line-height: 46px;
	font-weight: 700;
	border-radius: 40px;
	background: #35B6FF;
	background: linear-gradient(90deg, #0D93C6 0%, #35B6FF 100%);
	margin: 10px 0 0 0;
	transform: none;
}
</style>