<template>
	<div class="item">
		<div class="top">Habblet&nbsp;<span>Hot 5&trade;</span></div>
		<div class="content">
			<div class="box" v-for="(f, i) in fasites" :key="i">
				<div class="rank" :class="{'blue': f.rank == 1, 'rose': f.rank == 2, 'silver': f.rank == 3, 'white': f.rank > 3}">{{ f.rank }}</div>
				<div class="icon" :style="{backgroundImage: 'url('+f.icon+')'}"></div>
				<div class="info" :class="{'blue': f.rank == 1, 'rose': f.rank == 2, 'silver': f.rank == 3, 'white': f.rank > 3}">
					<div class="fasite">{{f.fasite}}</div>
					<div class="ouvintes"><span>{{f.ouvintes}}</span>&nbsp;ouvintes</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { base } from '../../../var.js';

export default {
	name: 'RankingFs',
	data() {
		return {
			fasites: []
		}
	},
	methods:{
		async getFS(){
			const req = await axios.get(base+'content/fasites/get')
			this.fasites = req.data
		}
	},
	mounted(){
		this.getFS()
		setInterval(() => {
			this.getFS()
		}, 30000);
	}
}
</script>

<style scoped>
.item {
	width: calc(var(--width) - 15px);
	left: -335px;
	height: auto;
	background-color: var(--color1);
	border-radius: 12px;
	padding: 10px;
}

.item .top {
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: center;
	font-size: 20px;
	color: #fff;
	font-weight: 300;
	line-height: 30px;
	user-select: none;
}
.item .top span {font-weight: 700;}

.item .content {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.item .box {
	position: relative;
	width: calc(100% / 5 - 10px);
	height: 178px;
	background-color: #fff;
	border-radius: 12px;
	user-select: none;
	margin-right: 10px;
}

.item .box .rank {
	position: relative;
	width: 32px;
	height: auto;
	border-radius: 12px;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	line-height: 32px;
	top: 10px;
	left: 10px;
}

.item .box .icon {
	width: 100%;
	height: 50px;
	background-repeat: no-repeat;
	background-position: center;
	margin: 4px 0;
}

.item .box .info {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 70px;
	border-radius: 0 0 12px 12px;
}


.item .box .white {background-color: #E8EFFC;color: var(--color2);}
.item .box > .blue {background-color: #60BEE1;}
.item .box > .rose {background-color: #CA70A3;}
.item .box > .silver {background-color: #95A9BD;}

.item .box .white .ouvintes {color: var(--color2);}

.item .box .info div {
	width: 100%;
	height: 20px;
	line-height: 20px;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	margin-top: 14px;
}

.item .box .info .fasite {color: rgba(0,0,0,.55);}

.item .box .info .ouvintes {
	margin-top: 4px;
}
.item .box .info .ouvintes span {font-weight: 700;}

@media only screen and (max-width: 500px) {
	.item {width: 100%;left: 0;}
	.item .content {
		display: block;
		width: 100%;
	}
	.item .content .box {width: calc(100% - 20px);margin: 10px 0 0 10px;float: left;}
}
</style>