<template>
	<div class="subModal">
		<form @submit.prevent="submitFile" enctype="multipart/form-data">
			<input type="file" name="alterar" accept="image/*" id="file" ref="file" @change="uploadfile()">
			<button>Confirmar</button>
		</form>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'AltAvatar',
	data(){
		return {
			file: '',
			textA: ''
		}
	},
	methods:{
		async submitFile() {
			let formData = new FormData();
			formData.append('file', this.file);

			const req = await axios.post(base+'page/profileImg',
				formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
			})
			this.$emit('textAlerta', req.data.text)
			setTimeout(() => {
				this.$emit('textAlerta', '')
			}, 3000);
		},
		uploadfile() {
			this.file = this.$refs.file.files[0];
		}
	}
}
</script>

<style scoped>

</style>