<template>
	<div class="subModal">
		<form @submit="setTic">
			<input type="text" placeholder="Assunto" v-model="assunto">
			<textarea name="text" placeholder="Digite..." v-model="text"></textarea>
			<button>Enviar</button>
		</form>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'OpenTic',
	data(){
		return {
			assunto: '',
			text: ''
		}
	},
	methods:{
		async setTic(e){
			e.preventDefault();
			const req = await axios.post(base+'page/tickets', {
				item: 'post',
				assunto: this.assunto,
				text: this.text
			})
			this.$emit('textAlerta', req.data.text)
		}
	}
}
</script>

<style scoped>

.subModal textarea {max-height: 136px;}
</style>