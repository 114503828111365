<template>
	<div class="item galeria">
		<div class="top">
			<div>
				<div class="icon"></div>
				<div class="title"><span>Galeria</span></div>
			</div>
			<div class="actions">
                <button :disabled='disableP == true' @click="getArt(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
                <button :disabled='disableN == true' @click="getArt(this.next)"><i class="fa-solid fa-angle-right"></i></button>
				<input type="text" name="search" placeholder="Pesquisar" v-model="search" @change.prevent="getArt()">
				<button><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
		</div>
		<div class="content">
			<div class="box animate__animated animate__headShake" v-for="a in art" :key="a.id" :style="{backgroundImage: 'url('+a.imagem+')'}">
				<router-link :to="{path: `/arte`, name: 'Arte', params: {id: a.id, url: a.url}}">
					<div class="data">
						<div class="info">
							<div class="title nobr">{{a.titulo}}</div>
							<div class="autor nobr">{{a.autor}}</div>
							<div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+a.autor+'?action=std&direction=4&head_direction=3&img_format=png&gesture=std&headonly=0&size=b&dance=0&frame_num=0&effect=0)'}"></div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

export default {
	name: 'GaleriaItem',
	data(){
		return {
			art: [],
			prev: 0,
			next: 0,
			disableN: false,
			disableP: false,
			search: ''
		}
	},
	methods: {
		async getArt(paga=1){
			const req = await axios.post(base+'getItens/art', {
				pag: paga,
				s: this.search
			})
			this.art = req.data.art
			this.prev = req.data.prev;
			this.next = req.data.next;

			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
	},
	mounted(){
		this.getArt();
	}
}
</script>

<style scoped>
.galeria {
	width: 100%;
	position: relative;
	height: 336px;
	padding: 10px;
	background-color: var(--color2);
	border-radius: 12px 0 0 12px;
}
.galeria::after {
	content: '';
	background-color: var(--color2);
	width: 100%;
	height: 100%;
	left: 100%;
	top: 0;
	position: absolute;
}

.galeria .top .icon {
	background-position: -32px -28px;
	background-color: #21415C;
}
.galeria .top .title{color: #fff;}

.galeria .content {
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.galeria .content .box {
	width: calc(33% - 10px);
	height: 264px;
	border-radius: 12px;
	background-position: center;
	background-size: cover;
}

.galeria .box .data {
	position: relative;
	top: 50%;
	width: 100%;
	height: 50%;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.5) 100%);
	border-radius: 0 0 12px 12px;
	transition: all .3s ease;
}
.galeria .box:hover .data {background: rgba(0,0,0,.5);border-radius: 12px;top: 0;height: 100%;}

.galeria .box .data .info {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.galeria .box .data .info .title {
	position: absolute;
	width: calc(100% - 70px);
	height: 24px;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	top: -26px;
	left: 10px;
}

.galeria .box .data .info .autor {
	position: absolute;
	left: 0;
	top: 0px;
	width: auto;
	max-width: 85%;
	height: 24px;
	border-radius: 6px;
	line-height: 24px;
	padding: 0 6px;
	color: #fff;
	font-size: 12px;
	margin: 4px 0 10px 10px;
	background-color: #CA70A3;
}

.galeria .box .data .info .avatar {
	float: right;
	width: 56px;
	height: 100px;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: -62px;
}
</style>