<template>
	<div class="modal config">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Configurações</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<div class="left">
					<ul>
						<li :class="{active: this.activeComponent == 'AltAvatar'}" @click="activeComponent = 'AltAvatar'"><p>Alterar avatar</p></li>
						<li :class="{active: this.activeComponent == 'AltBackg'}" @click="activeComponent = 'AltBackg'"><p>Alterar background</p></li>
						<li :class="{active: this.activeComponent == 'AltPass'}" @click="activeComponent = 'AltPass'"><p>Alterar senha</p></li>
						<li :class="{active: this.activeComponent == 'OpenTic'}" @click="activeComponent = 'OpenTic'"><p>Abrir ticket</p></li>
						<li :class="{active: this.activeComponent == 'MineTic'}" @click="activeComponent = 'MineTic'"><p>Meus tickets</p></li>
						<li :class="{active: this.activeComponent == 'MyArt'}" @click="activeComponent = 'MyArt'"><p>Minhas artes</p></li>
						<li :class="{active: this.activeComponent == 'MyBadges'}" @click="activeComponent = 'MyBadges'"><p>Alterar emblemas</p></li>
					</ul>
				</div>
				<div class="subcontent">
					<KeepAlive>
						<Transition name="custom-classes" enter-active-class="animate__animated animate__flipInX" leave-active-class="animate__animated animate__rollOut">
							<component :is="activeComponent" :key="activeComponent" @textAlerta="setAlert($event)" 
							@TickChat="ticketId" :ticketid="this.tickId"
							></component>
						</Transition>
					</KeepAlive>
				</div>
			</div>
		</div>
		<Transition name="slide-fade">
			<Alerta v-if="this.textA != ''" :text="this.textA" />
		</Transition>
	</div>
</template>

<script>
import Alerta from '../usefulness/alert.vue'

import AltAvatar from '../configs/avatar.vue'
import AltPass from '../configs/pass.vue'
import OpenTic from '../configs/open-ticket.vue'
import MineTic from '../configs/mine-tickets.vue'
import MyArt from '../configs/mine-arts.vue'
import ChatTicketItem from '../configs/chatTicket.vue'
import AltBackg from '../configs/capa-perfil.vue'
import MyBadges from '../configs/my-badges.vue'

export default {
	name: 'ModConfig',
	components: {
		Alerta,
		AltAvatar,
		AltPass,
		OpenTic,
		MineTic,
		MyArt,
		ChatTicketItem,
		AltBackg,
		MyBadges
	},
	emits: ['textAlerta'],
	data(){
		return {
			activeComponent: 'AltAvatar',
			textA: '',
			tickId: 0
		}
	},
	methods:{
		setAlert(t){
			this.textA = t;
			setTimeout(() => {
			this.textA = ''
		}, 3000);
		},
		ticketId(i){
			this.tickId = i
			this.activeComponent = 'ChatTicketItem'
		}
	},
	mounted(){
		setTimeout(() => {
			this.textA = ''
		}, 3000);
	}
}
</script>

<style scoped>
.config .box {
	position: relative;
	width: 450px;
	height: 300px;
}

.config .box .top {height: 50px;}

.config .box .content {
	float: left;
	width: 100%;
	height: calc(100% - 50px);
	padding: 0;
	display: flex;
	justify-content: space-between;
	z-index: 0;
}

.config .box .content .left {
	position: relative;
	top: 0;
	left: 0;
	width: 40%;
	height: auto;
	background-color: #EFF2F5;
	border-bottom-left-radius: 12px;
}
/* .config .box .content .left::before {
	content: '';
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	background-color: #EFF2F5;
} */

.config .box .content .left ul {
	float: left;
	margin-top: 10px;
}

.config .box .content .left li {
	float: left;
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	font-weight: 300;
	padding: 0 10px;
	cursor: pointer;
	margin-top: 2px;
}
.config .box .content .left li.active p {font-weight: 600; transition: all .2s ease;}
.config .box .content .left li:nth-child(2n) {background-color: #E4E9EE;}

.config .box .content .subcontent {
	position: relative;
	top: 0;
	right: 0;
	width: 60%;
	height: auto;
	padding-bottom: 10px;
	z-index: 1;
}

.subModal {
	position: relative;
	width: 100%;
	height: auto;
	padding: 8px;
}
</style>