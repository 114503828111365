<template>
    <div class="topic item">
        <div class="top">
            <div class="left">
                <div class="icon"></div>
                <div class="title"><span>Fórum</span></div>
            </div>
            <div class="actions">
                <button :disabled='prev == 0' @click="getTopics(prev)"><i class="fa-solid fa-angle-left"></i></button>
                <button :disabled='total < next' @click="getTopics(next)"><i class="fa-solid fa-angle-right"></i></button>
                <input type="text" name="search" placeholder="Pesquisar" v-model="search" @keydown.enter="getTopics()">
                <button><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
        <div class="content">
            <div class="box" v-for="(t, i) in topics" :key="i">
				<div class="line" :class="{'new': t.new == true, 'fix': t.fix == 'true'}"></div>
				<div class="box-avatar" :style="{backgroundImage: 'url('+t.photo+')'}"></div>
				<div class="box-data">
                    <router-link :to="{path: `/topico`, name: 'TopicPage', params: {id: t.id, url: t.url}}">
                        <div class="title nobr" :title="t.titulo">{{ t.titulo }}</div>
                    </router-link>
                    <div class="flex">
                        <div class="user">
                            <span><i class="fa-solid fa-user"></i>&nbsp;&nbsp;{{t.autor}}</span>
                            <div class="new" v-if="t.new == true">NOVO</div>
                        </div>
                        <div class="comments"><i class="fa-solid fa-message"></i>&nbsp;{{t.comments}}</div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {base} from '../../../var.js'
import axios from 'axios'

export default {
    name: 'ItemTopics',
    data() {
        return {
            topics: [],
            prev: 0,
            next: 0,
            total: 0,
            disableP: true,
            disableN: true,
            search: '',
        }
    },
    methods: {
        async getTopics(paga=1) {
			const req = await axios.post(base+'getItens/topics', {
                pag: paga,
                s: this.search
            });
			this.topics = req.data.topics;
			this.prev = req.data.prev;
			this.next = req.data.next;
            this.total = req.data.total;
			
			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
    },
    mounted(){
        this.getTopics();
    }
}
</script>

<style scoped>
.topic .top .icon {
	background-color: #36628A;
	background-position: -30px -64px;
}

.topic .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.topic .content .box {
    position: relative;
    width: calc(50% - 10px);
    height: 86px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.topic .content .box .line {
    position: absolute;
    top: 16px;
    left: 0;
    width: 4px;
    height: 54px;
    background-color: var(--color1);
    border-radius: 0 4px 4px 0;
    transition: all .3s ease;
}
.topic .content .box .line.new {background-color: #2FC145;}
.topic .content .box .line.fix {background-color: #FFD976;}
.topic .content .box:hover .line {width: 7px;}

.topic .box .box-avatar {
    width: 86px;
    height: 100%;
    border-radius: 12px 0 0 12px;
    background-color: #F8FAFC;
    background-position: center;
    background-size: cover;
}

.topic .box .box-avatar .avatar {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.topic .box .box-data {
    width: calc(100% - 90px);
    height: 30px;
    padding: 0 10px;
}

.topic .box .box-data .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.topic .box .box-data .title {
    float: left;
    width: 100%;
    height: 25px;
    line-height: 25px;
    font-size: 15px;
    font-weight: 500;
    color: var(--color1);
    margin-top: 12px;
}

.topic .box .box-data .user,
.topic .box .box-data .comments {
    float: left;
    width: 80%;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    margin-top: 8px;
    color: #444;
    font-weight: 600;
    user-select: none;
    padding-right: 8px;
}

.topic .box .box-data .comments {width: auto;}

.topic .box .box-data .user span {
    float: left;
}

.topic .box .box-data .new {
    float: left;
    width: auto;
    height: 18px;
    background-color: #2FC145;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 800;
    padding: 0 6px;
    line-height: 19px;
    margin: 6px 0 0 6px;
}


@media only screen and (max-width: 500px) {

    .topic .content .box {width: 100%;}
}

</style>