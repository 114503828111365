<template>
    <div>
        <NewsItem/>
        <EventItem/>
        <PodItem/>
        <ItemTopics/>
        <GaleriaItem/>
        <ShopItem/>
        <RankingFs/>
    </div>
</template>

<script>
import NewsItem from '../components/home/news.vue'
import EventItem from '../components/home/eventos.vue'
import PodItem from '../components/home/podcast.vue'
import GaleriaItem from '../components/home/galeria.vue'
import ShopItem from '../components/home/shop.vue'
import ItemTopics from '../components/home/topicos.vue'
import RankingFs from '@/components/home/ranking-fs.vue'

export default {
    name: 'MainItem',
    components: {
    NewsItem,
    EventItem,
    PodItem,
    GaleriaItem,
    ShopItem,
    ItemTopics,
    RankingFs
},
    data() {
        return {
            on: false
        }
    },
    mounted(){
        document.title = 'Rádio Habblet • Um novo jeito de fazer fã-site!';
    }
}
</script>