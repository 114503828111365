<template>
    <div class="badges item">
        <div class="top">
            <div class="left">
                <div class="icon"></div>
                <div class="title"><span>Emblemas</span> gratis</div>
            </div>
            <div class="actions">
				<button :disabled='disableP == true' @click="getBad(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled='disableN == true' @click="getBad(this.next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
        </div>
        <div class="content">
            <div class="box animate__animated animate__headShake" v-for="(b, index) in badges" :key="index" :style="{backgroundImage: 'url(https://images.habblet.city/c_images/album1584/'+b.code+'.gif)'}">
                    <ItemTip :title="b.titulo" top="-50%"/>
                <router-link v-if="b.id != 0" :to="{path: `noticia/${b.id}/${b.url}`, name: 'News', params: {id: b.id, url: b.url}}">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'
import ItemTip from '../usefulness/tooltip.vue'

export default {
    name: 'BadgesFree',
    components: {
        ItemTip
    },
    data() {
        return {
            badges: [],
            prev: 0,
            next: 0,
            disableP: false,
            disableN: false
        }
    },
    methods: {
        async getBad(pag){
            const req = await axios.post(base+'getItens/badges-free', {
                pag: pag
            })
            this.badges = req.data.badges
            this.prev = req.data.prev
            this.next = req.data.next

            if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
        }
    },
    mounted(){
        this.getBad()
    }
}
</script>

<style scoped>
.badges {
    position: relative;
    width: 100%;
    height: 340px;
    background-color: #E6EBF1;
    border-radius: 0 12px 12px 0;
    padding: 10px 8px 0 0;
}
.badges::before {
    content: '';
    width: 300%;
    height: 100%;
    background-color: #E6EBF1;
    position: absolute;
    top: 0;
    left: -300%;
}

.badges .top .icon {
    background-color: #2FC145;
    background-position: -36px -96px;
}

.badges .content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.badges .content .box {
    position: relative;
    float: left;
    width: 90px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    background-color: #C3CED9;
    transition: all .4s ease;
    margin: 0 8px 10px 2px;
}
.badges .content .box a {width: 100%;height: 100%;float: left;}

/* .badges .content .box::after {
    content: '';
    position: absolute;
    float: left;
    width: 90px;
    height: 10px;
    border-radius: 12px 12px 0 0;
    background-color: #C3CED9;
    transition: all .4s ease;
    margin: 90px 0 0 0px;
} */

.badges .content .box:hover {background-color: #2FC145;}

</style>