<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+$store.state.initUser.user+'&action=std&direction=2&head_direction=2&img_format=png&gesture=std&headonly=0&size=l&dance=0&frame_num=0&effect=0)'}">
				<div class="white"></div>
				<div class="money"><i class="fa-solid fa-coins"></i>&nbsp;{{$store.state.initUser.moedas}}</div>
			</div>
			<router-link :to="{name: 'ProfilePage'}">
				<button class="green" @click="$emit('close')">Meu perfil</button>
			</router-link>
			<button class="yellow" @click="modA = true">Postar arte</button>
			<button class="pink" @click="modC = true">Configurações</button>
			<button class="logout blue" @click="deslog()">Sair</button>
		</div>
	</div>
	<Transition name="slide-fade">
		<ModArt v-if="modA" @close="modA = false" />
	</Transition>
	<Transition name="slide-fade">
		<ModConfig v-if="modC" @close="modC = false" />
	</Transition>
</template>

<script>
import {base} from '../../../var.js'
import axios from 'axios'

import ModArt from '../modals/art.vue'
import ModConfig from '../modals/config.vue'

export default {
	name: 'ModUser',
	components: {
		ModArt,
		ModConfig
	},
	data() {
		return {
			modA: false,
			modC: false
		}
	},
	methods:{
		async deslog(){
			const req = await axios.get(base+'page/logout')
			this.alert = req.data.text

			this.$emit('close')
			this.$store.commit('storeUser', {user: [], logged: false})
		}
	}
}
</script>

<style scoped>

.box {
	width: 300px !important;
	background-color: rgb(236, 241, 235) !important;
	border-radius: 20px !important;
	padding: 0 20px 20px 20px;
}

.box .avatar {
	position: relative;
	width: 100%;
	height: 130px;
	background-repeat: no-repeat;
	background-position: center -70px;
	margin-top: -20px;
}

.box .avatar .white {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: calc(50% + 1px);
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(236, 241, 235) 100%);
}

.box .avatar .money {
	position: absolute;
	top: 86px;
	left: 65%;
	width: auto;
	height: 32px;
	border-radius: 16px;
	background-color: #F7BD15;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	line-height: 32px;
	padding: 0 10px;
}

.box button {
	width: 100%;
	height: 44px;
	background-color: #fff;
	border: 2px solid transparent;
	border-radius: 40px;
	margin-top: 10px;
	font-size: 15px;
	font-weight: 500;
}
.box button.green {background: linear-gradient(white, white) padding-box, linear-gradient(to right, #35B6FF, #2EC044) border-box;}
.box button.yellow {background: linear-gradient(white, white) padding-box, linear-gradient(to right, #F7BD15, #CF48FF) border-box;}
.box button.pink {background: linear-gradient(white, white) padding-box, linear-gradient(to right, #FF3C6A, #2788CF) border-box;}
.box button.blue {background-color: #0D93C6;color: #fff;font-weight: 600;border: 0;}
</style>