<template>
    <div class="news item">
        <div class="top">
            <div class="left">
                <div class="icon"></div>
                <div class="title"><span>Notícias</span></div>
            </div>
            <div class="actions">
                <button :disabled='disableP == true' @click="getNews(this.prev)"><i class="fa-solid fa-angle-left"></i></button>
                <button :disabled='disableN == true' @click="getNews(this.next)"><i class="fa-solid fa-angle-right"></i></button>
                <input type="text" name="search" placeholder="Pesquisar" v-model="search" @change="getNews()">
                <button><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
        <div class="content">
            <div class="box animate__animated animate__headShake" v-for="n in news" :key="n.id">
                <router-link :to="{path: `noticia`, name: 'News', params: {id: n.id, url: n.url}}">
                    <div class="img" :style="{backgroundImage: 'url('+n.imagem+')'}"></div>
                    <div class="title nobr">{{n.titulo}}</div>
                    <div class="desc">{{n.descricao}}</div>
                    <div class="comments"><i class="fa-solid fa-message"></i>&nbsp;{{ n.comments}}</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {base} from '../../../var.js'
import axios from 'axios'

export default {
    name: 'NewsItem',
    data() {
        return {
            news: [],
            prev: 0,
            next: 0,
            disableP: true,
            disableN: true,
            search: ''
        }
    },
    methods: {
        async getNews(paga=1) {
			const req = await axios.post(base+'getItens/news', {
                pag: paga,
                s: this.search
            });
			this.news = req.data.news;
			this.prev = req.data.prev;
			this.next = req.data.next;
			
			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
    },
    mounted(){
        this.getNews();
    }
}
</script>

<style scoped>

.news {margin-bottom: 10px;}

.news .top .icon {
    background-color: #21415C;
    background-position: -34px 4px;
}

.news .content {
    display: flex;
    flex-flow: wrap;
}

.news .content .box {
    position: relative;
    width: calc(50% - 8px);
    height: 92px;
    background-color: #fff;
    border-radius: 12px;
    margin: 8px 4px 0 4px;
    transition: all .3s ease;
}
.news .content .box:hover {background-color: #E4E9EE;}

.news .box .img {
    float: left;
    width: 120px;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-color: #ccc;
    border-radius: 12px 0 0 12px;
}

.news .box .title {
    float: left;
    width: calc(100% - 130px);
    height: 20px;
    margin: 12px 0 0 10px;
    color: var(--color1);
    font-size: 14px;
    font-weight: 500;
}

.news .box .desc {
    float: left;
    width: calc(100% - 130px);
    height: 35px;
    margin: 4px 0 0 10px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news .box .comments {
    font-size: 12px;
    font-weight: 600;
    float: right;
    margin: 0px 10px 0 0;
}
</style>