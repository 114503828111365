<template>
	<div class="modal perfil-box">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			<div class="back" :style="{backgroundColor: 'var(--color2)', backgroundImage: 'url('+userA.background+')'}"></div>
			<div class="ftUser" :style="{backgroundImage: 'url('+userA.image+')'}"></div>
			<div class="line">
				<div><span>{{comments}}</span> comentários</div>
				<div class="cargo"><span>{{userA.cargo}}</span></div>
				<div><span>{{arts}}</span> artes</div>
			</div>
			<div class="buttons">
				<div class="title">Emblemas do site:</div>
				<div>
					<button :disabled='disableP == true' @click="getUser(user, prev)"><i class="fa-solid fa-angle-left"></i></button>
					<button :disabled='disableN == true' @click="getUser(user, next)"><i class="fa-solid fa-angle-right"></i></button>
				</div>
			</div>
			<div class="badges">
				<div class="boxItem tip" v-for="(b, index) in badges" :key="index" :style="{backgroundImage: 'url('+b.badge_imagem+')'}">
					<MyTip top="-96%" :title="b.title+'<br>'+b.description" line="26px"/>
				</div>
			</div>
			<router-link :to="{path: 'profile/'+nick, name: 'ProfilePage', params: {user: nick}}">
				<button class="ir-perfil" v-on:click="$emit('close')">Ver perfil completo de {{ userA.nick }}</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'
import MyTip from '../usefulness/tooltip.vue'

export default {
	name: 'ItemPerfil',
	components: {
		MyTip
	},
	props: ['user'],
	data() {
		return {
			nick: this.user,
			arts: 0,
			comments: 0,
			badges: [],
			userA: [],
			next: 0,
			prev: 0,
			disableP: false,
			disableN: false
		}
	},
	methods: {
		async getUser(user, pag=1) {
			const req = await axios.post(base+'getItens/perfilUser', {
				user: user,
				pag: pag
			})
			this.badges = req.data.badges
			this.arts = req.data.artes
			this.comments = req.data.comments
			this.userA = req.data.user
			this.nick = req.data.user.nick

			this.prev = req.data.prev
			this.next = req.data.next

			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
	},
	mounted () {
		this.getUser(this.user);
	},
}
</script>

<style scoped>
.modal {padding: 0;}

.modal .empty {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
}
.modal .box {position: absolute;z-index: 1;}

.modal .content,
.modal .top { box-shadow: none;padding: 0;margin: 0;}
.modal .close {
	position: absolute;
	right: 3px;
	top: 3px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
}

.modal .back {
	float: left;
	width: 100%;
	height: 95px;
	border-radius: 12px 12px 0 0;
	background-position: center;
	background-size: cover;
}

.modal .ftUser {
	position: relative;
	float: left;
	width: 90px;
	height: 90px;
	border-radius: 70px;
	border: 2px solid #fff;
	margin: -80px 0 0 50%;
	transform: translateX(-50%);
	background-color: #ccc;
	background-position: center;
	background-size: cover;
	z-index: 1;
}

.modal .line {
	position: relative;
	float: left;
	width: 100%;
	height: 50px;
	line-height: 30px;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #eee;
	margin-top: -10px;
	padding: 0 10px;
	z-index: 0;
}

.modal .line div {
	position: relative;
	top: 0;
	left: 0;
	width: 30%;
	height: auto;
	text-align: center;
	line-height: 20px;
	transition: all .3s ease;
}

.modal .line div:hover {color: var(--color1);}
.modal .line span {font-weight: 600;}
/* .modal .line .cargo {line-height: 18px;} */

.modal .badges {
	position: relative;
	float: left;
	width: calc(100% - 10px);
	height: auto;
	display: flex;
	flex-flow: wrap;
	margin-left: 5px;
	padding: 10px 0;
	z-index: 0;
}

.modal .buttons {
	float: left;
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}
.modal .buttons button {
	width: 30px;
	height: 30px;
	border-radius: 30px;
	background-color: #ccc;
	margin-right: 6px;
	color: #fff;
}
.modal .buttons .title {
	float: left;
	font-size: 13px;
	font-weight: 500;
	margin: 5px 0 0 8px;
}

.modal .badges .boxItem {
	position: relative;
	float: left;
	width: 58px;
	height: 58px;
	border-radius: 10px;
	background-color: #f1f1f1;
	background-position: center;
	background-repeat: no-repeat;
	margin: 6px 0 0 6px;
	transition: all .3s ease-in-out;
}
.modal .badges .boxItem:hover {background-color: var(--color1);}

.perfil-box .box .ir-perfil {
	float: left;
	width: calc(100% - 20px);
	height: 40px;
	margin: 10px 0 10px 10px;
	background-color: var(--color1);
	border-radius: 12px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
}
</style>