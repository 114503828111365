<template>
	<div class="subModal">
		<div class="item">{{this.assunto}}</div>
		<div class="item" v-html="this.msg"></div>
		<div class="item res" v-html="'Resposta: '+this.chat"></div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

export default {
	name: 'ChatTicketItem',
	props: ['ticketid'],
	data(){
		return {
			chat: '',
			assunto: '',
			msg: ''
		}
	},
	methods: {
		async getChat(){
			const req = await axios.post(base+'getItens/chatTicket', {
				id: this.ticketid
			})
			this.assunto = req.data.assunto
			this.msg = req.data.msg
			this.chat = req.data.chat ?? 'Sem resposta!'
		}
	},
	mounted(){
		this.getChat()
	}
}
</script>

<style scoped>
.subModal .item {
	float: left;
	width: 100%;
	height: auto;
	min-height: 36px;
	background-color: #EFF2F5;
	border-radius: 12px;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 16px;
	margin-bottom: 8px;
}

.subModal .item.res {background-color: #E4E9EE;}

</style>