import { createRouter, createWebHistory } from 'vue-router'
import MainItem from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainItem
  },
  {
    path: '/noticia/:id/:url',
    name: 'News',
    component: () => import(/* webpackChunkName: "noticia" */ '../views/pageNews.vue'),
    props: true,
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/error.vue'),
  },
  {
    path: '/arte/:id/:url',
    name: 'Arte',
    component: () => import(/* webpackChunkName: "arte" */ '../views/pageArt.vue'),
  },
  {
    path: '/pagina/imager',
    name: 'imager',
    component: () => import(/* webpackChunkName: "imager" */ '../views/pageImager.vue'),
    props: true
  },
  {
    path: '/pagina/equipe',
    name: 'equipe',
    component: () => import(/* webpackChunkName: "team" */ '../views/pageTeam.vue'),
  },
  {
    path: '/pagina/horarios',
    name: 'horarios',
    component: () => import(/* webpackChunkName: "horarios" */ '../views/horarios.vue'),
  },
  {
    path: '/pagina/valores',
    name: 'valores',
    component: () => import(/* webpackChunkName: "valores" */ '../views/valores.vue'),
  },
  {
    path: '/pagina/ranking',
    name: 'Ranking',
    component: () => import(/* webpackChunkName: "ranking" */ '../views/ranking.vue'),
    props: true
  },
  {
    path: '/pagina/recordes',
    name: 'recordes',
    component: () => import(/* webpackChunkName: "recordes" */ '../views/records.vue'),
  },
  {
    path: '/pagina/:id',
    name: 'pagina',
    component: () => import(/* webpackChunkName: "pagina" */ '../views/pagina.vue'),
    props: true
  },
  {
    path: '/comunidade',
    name: 'Comunidade',
    component: () => import(/* webpackChunkName: "comunidade" */ '../views/comunidade.vue')
  },
  {
    path: '/api',
    name: 'HabbletApi',
    component: () => import(/* webpackChunkName: "api" */ '../views/pageApi.vue'),
  },
  {
    path: '/profile-api',
    name: 'ProfileApi',
    component: () => import(/* webpackChunkName: "profile" */ '../views/pageProfileApi.vue'),
  },
  {
    path: '/topico/:id/:url',
    name: 'TopicPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-topic" */ '../views/pagetopic.vue'),
  },
  {
    path: '/profile/:user?',
    name: 'ProfilePage',
    props: true,
    component: () => import(/* webpackChunkName: "profile" */ '../views/pageProfile.vue'),
  },
  {
    path: '/post-topic',
    name: 'PostTopic',
    component: () => import(/* webpackChunkName: "post-topic" */ '../views/post-topic.vue'),
  },
  {
    path: '/wireds',
    name: 'wireds',
    component: () => import(/* webpackChunkName: "wreds" */ '../views/pageWired'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "error" */ '../views/error.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
