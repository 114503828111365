<template>
	<div class="item event">
		<div class="top">
			<div class="left">
				<div class="icon"></div>
				<div class="title"><span>Eventos</span></div>
			</div>
			<div class="actions">
				<button :disabled="disableP == true" @click="getEvent(prev)"><i class="fa-solid fa-angle-left"></i></button>
				<button :disabled="disableN == true" @click="getEvent(next)"><i class="fa-solid fa-angle-right"></i></button>
			</div>
		</div>
		<div class="content">
			<div class="box animate__animated animate__headShake" v-for="e in events" :key="e.id">
				<router-link :to="{path: `/noticia`, name: 'News', params: {id: e.id, url: e.url}}">
					<div class="img" :style="{backgroundImage: 'url('+e.imagem+')'}"></div>
					<div class="title nobr">{{e.titulo}}</div>
					<div class="time">{{e.time}}</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
	name: 'EventItem',
	data(){
		return {
			events: [],
			next: 0,
			prev: 0,
			disableP: false,
			disableN: false
		}
	},
	methods: {
		async getEvent(pag=1){
			const req = await axios.post(base+'getItens/events', {
				pag: pag
			})
			this.events = req.data.events
			this.next = req.data.next
			this.prev = req.data.prev
			
			if(req.data.prev == 0){this.disableP = true;}else {this.disableP = false;}
			if(req.data.next > req.data.total){this.disableN = true;}else {this.disableN = false;}
		}
	},
	mounted(){
		this.getEvent();
	}
}
</script>

<style scoped>
.event {
	float: left;
	width: calc(50% - 10px);
	margin-top: 40px;
}

.event .top .icon {
	background-color: #F7BD15;
	background-position: -70px 3px;
}

.event .content .box {
	width: 100%;
	float: left;
	height: 86px;
	background-color: #fff;
	border-radius: 12px;
	margin-top: 10px;
	transition: all .3s ease;
}
.event .content .box:hover {background-color: #E4E9EE;}
.event .content .box:first-of-type {margin-top: 0px;}

.event .box .img {
	float: left;
	width: 90px;
	height: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 12px 0 0 12px;
	background-color: cornflowerblue;
}

.event .box .title,
.event .box .time {
	float: left;
	width: calc(100% - 100px);
	height: 20px;
	margin-left: 10px;
}

.event .box .title {
	font-size: 14px;
	font-weight: 500;
	margin-top: 16px;
	color: var(--color1);
}

.event .box .time {
	width: auto;
	height: 28px;
	padding: 0 6px;
	font-size: 12px;
	font-weight: 500;
	line-height: 28px;
	color: #112535;
	background-color: #EFF2F5;
	border-radius: 6px;
	margin-top: 8px;
}

</style>